import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/interfaces/user';
import { DataService } from 'src/app/services/data.service';
import { Common } from '../delivery-address/delivery-address.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit {

  public CHARACTER_WITH_SPACE: RegExp = /^[a-zA-Z\s]+$/;

  public form: FormGroup = new FormGroup({
    firstName: new FormControl(null, [Validators.pattern(this.CHARACTER_WITH_SPACE), Validators.maxLength(50)]),
    middleName: new FormControl(null, [Validators.pattern(this.CHARACTER_WITH_SPACE), Validators.maxLength(50)]),
    lastName: new FormControl(null, [Validators.pattern(this.CHARACTER_WITH_SPACE), Validators.maxLength(50)]),
    dob: new FormControl(null),
    gender: new FormControl(null),
    email: new FormControl(null,Validators.email),
    userType: new FormControl(null),
    schoolName: new FormControl(null),
    countryId: new FormControl(null),
    stateId: new FormControl(null),
    cityId: new FormControl(null),
    pin: new FormControl(null),
    userName: new FormControl(null)
  });
  public school_placeholder: string = 'Enter Your School Name';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public states: Array<Common> = [];
  public cities: Array<Common> = [];
  public countires: Array<Common> = [];

  constructor(
    private _dataService: DataService,
    private _toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this._dataService.lookup().toPromise().then((response: any) => {
      this.states = response.result[0].values;
      this.cities = response.result[1].values;
      this.countires = response.result[2].values;
    });

    this._dataService.getUser().toPromise().then((user: User) => {
      this.form.patchValue(user);
      if(user.dob){
        let d = new Date(user.dob);
        this.form.patchValue({
          dob: new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDay()+1)
        })
      }
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    });
  }

  ngAfterViewInit(): void {
    this.form.get('userType').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((type) => {
      switch (type) {
        case 'College Student':
          this.school_placeholder = 'Enter your college name';
          break;
        case 'Graduate':
          this.school_placeholder = 'Enter your degree name';
          break;
        case 'Preparing for the exam':
          this.school_placeholder = 'Enter the exam name';
          break;
        case 'Other':
          this.school_placeholder = 'Enter your edu info';
          break;
        default:
          this.school_placeholder = 'Enter your school name';
          break;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  public beforeChange($event: NgbPanelChangeEvent) {
		if ($event.panelId === 'toggle-1' && $event.nextState === false) {
			$event.preventDefault();
		}
	}

  public submit(){
    if(this.form.valid){
      this.form.disable();
      const dob = this.form.value.dob
      if(dob){
        this.form.value.dob = new Date(dob.year, dob.month - 1, dob.day - 1).toISOString();
      }
      this._dataService.updateUser(this.form.value).toPromise().then((res) => {
        this._toaster.success('Profile updated.', 'Success');
      }).catch((ex) => {
        this._toaster.error(ex.error.Message, 'Error');
      }).finally(() => {
        this.form.enable();
      });
    }
  }

  /* return today date */
  get today(): NgbDateStruct{
    let d = new Date();
    return {day: d.getDate(), month: d.getMonth()+1, year: d.getFullYear()};
  }

}
