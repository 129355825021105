import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { AuthUtils } from './auth.utils';
import { UserService } from './user/user.service';


@Injectable()
export class AuthService
{
    public _authenticated: boolean = false;
    public _authStatus: BehaviorSubject<any> = new BehaviorSubject(null);
    public _authStatusObservable: Observable<any>;

    /**
     * Constructor
     */
    constructor(
        private _userService: UserService
    )
    {
        this._authStatus.next(this.accessToken);
        this._authStatusObservable = this._authStatus.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        // this._authStatus.next(token);
        localStorage.setItem('yournotebook.token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('yournotebook.token') ?? undefined;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._authStatusObservable.pipe(
            switchMap((response: any) =>{
                // Store the access token in the local storage
                this.accessToken = response;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response;

                // Return true
                return of(true);
            })
        )
    }


    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
