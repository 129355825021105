<div class="w-100 position-sticky top-0 z-index">
  <!-- <div class="upper-header">
    <div class="container-xxl">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="font14 txt-black">
            Call: <a href="tel:+917249384189" class="font14 txt-black">+91 7249384189</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="font14 txt-black text-center">
            Get <span class="blue-txt bold font14">
              Upto 70%
              Off
            </span> on our wide range of products! 
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
        </div>
      </div>
    </div>
  </div> -->

  <nav class="navbar navbar-expand-lg bg-light navbar-light custom-navbar">
    <div class="container-xxl">
      <a class="navbar-brand" routerLink="/">
        <img src="assets/images/logo.png" height="50" alt="YourNoetbook">
      </a>

      <div class="d-lg-none d-flex align-items-center justify-content-end">
        <button *ngIf="!_authService.accessToken" class="btn me-2 login-btn" (click)="openLoginModal(login)">Login <img src="assets/images/ic_round-login.svg"
          loading="lazy" alt="ic_round-login">
        </button>
        <button *ngIf="_authService.accessToken" class="btn me-2 add-to-cart-btn blue-txt" [routerLink]="'/account'">My Account <img src="assets/images/user-check.svg"
          loading="lazy" alt="user-check">
        </button>
        <button class="btn add-to-cart-btn blue-txt" [routerLink]="'/cart'">My cart
          <img src="assets/images/shopping-bag.svg" loading="lazy" alt="shopping-bag">
          <span class="ms-1" *ngIf="getCartItemCount > 0">({{getCartItemCount}})</span>
        </button>
      </div>

      <div class="collapse navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <ul class="navbar-nav">
          <li class="nav-item location my-md-2 mb-lg-0">
            <button class="btn nav-link location-btn d-flex justify-content-start align-items-center" (click)="openLocationModal(location)">
              <img src="assets/images/location-icon.svg" alt="location-icon" class="location-icon-cls me-1">
              <p class="blue-txt m-0">{{userAddress ? userAddress : 'Choose Your Location'}}</p>
            </button>
          </li>
        </ul>
        <form class="mx-lg-4 my-auto my-md-2 mb-lg-0 d-lg-inline w-100">
          <div class="search-box">
            <div class="w-100">
              <input type="search"
                     [formControl]="_searchService.searchTerm"
                     [ngbTypeahead]="search"
                     [editable]="true"
                     [focusFirst]="false"
                     [showHint]="true"
                     [resultFormatter]="formatter"
                     [inputFormatter]="inputFormatter"
                     (selectItem)="selectedItem($event)"
                     (keyup.enter)="searchResult()"
                     placeholder="search for notebook, pencil, eraser, calculator, gifts etc"
                     class="searchinput">
            </div>
            <span>
              <i class="fa fa-search"></i>
            </span>
          </div>
        </form>
        <ul class="navbar-nav">
          <li class="nav-item my-md-2 mb-lg-0" *ngIf="!_authService.accessToken">
            <button class="btn login-btn" (click)="openLoginModal(login)">Login <img src="assets/images/ic_round-login.svg"
                         loading="lazy" alt="ic_round-login">
            </button>
          </li>
          <li class="nav-item my-md-2 mb-lg-0" *ngIf="_authService.accessToken">
            <button class="btn add-to-cart-btn blue-txt" [routerLink]="'/account'">My Account <img src="assets/images/user-check.svg"
              loading="lazy" alt="user-check">
            </button>
          </li>
          <li class="nav-item my-md-2 mb-lg-0">
            <button class="btn add-to-cart-btn blue-txt" [routerLink]="'/cart'">My cart
              <img src="assets/images/shopping-bag.svg" loading="lazy" alt="shopping-bag">
              <span class="ms-1" *ngIf="getCartItemCount > 0">({{getCartItemCount}})</span>
            </button>
          </li>
          <li class="nav-item my-md-2 mb-lg-0">
            <button class="btn btn-link nav-link noti-icon" (click)="openNotification(notifications)">
              <span *ngIf="_dataService.notifications_data.length > 0">{{_dataService.notifications_data.length}}</span>
              <img src="assets/images/bell.png" loading="lazy" alt="notification-icon">
            </button>
          </li>
        </ul>
      </div>
    </div>
    
    <div class="container-xxl d-lg-none">
      <form class="mx-lg-4 my-auto my-md-2 mb-lg-0 d-lg-inline w-100">
        <div class="search-box">
          <div class="w-100">
            <input type="search"
                   [formControl]="_searchService.searchTerm"
                   [ngbTypeahead]="search"
                   [editable]="true"
                   [focusFirst]="false"
                   [showHint]="true"
                   [resultFormatter]="formatter"
                   [inputFormatter]="inputFormatter"
                   (selectItem)="selectedItem($event)"
                   (keyup.enter)="searchResult()"
                   placeholder="search for notebook, pencil, eraser, calculator, gifts etc"
                   class="searchinput">
          </div>
          <span>
            <i class="fa fa-search"></i>
          </span>
        </div>
      </form>
    </div>

    <div class="container-xxl d-lg-none">
      <div class="d-lg-none d-flex align-items-center justify-content-between w-100">
        <button class="btn nav-link location-btn d-flex justify-content-start align-items-center" (click)="openLocationModal(location)">
          <img src="assets/images/location-icon.svg" alt="location-icon" class="location-icon-cls me-1">
          <p class="blue-txt m-0">{{userAddress ? userAddress : 'Choose Your Location'}}</p>
        </button>
        
        <button class="btn btn-link nav-link noti-icon" (click)="openNotification(notifications)">
          <span *ngIf="_dataService.notifications_data.length > 0">{{_dataService.notifications_data.length}}</span>
          <img src="assets/images/bell.png" loading="lazy" alt="notification-icon">
        </button>
      </div>
    </div>
  </nav>
</div>

<!-- SIGN IN MODAL -->
<ng-template #login let-modal>
  <div class="modal-body login-modal position-relative py-4 px-lg-5">
    <button type="button" class="btn-close modal-btn-close" (click)="modal.dismiss('Cross click')"></button>
    <app-login (onLogin)="onUserLogin($event)"></app-login>
  </div>
</ng-template>


<ng-template #notifications let-offcanvas>
	<div class="offcanvas-header">
		<h4 class="offcanvas-title" id="offcanvas-basic-title">Notifications</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
	</div>
	<div class="offcanvas-body notification-drawer border-top p-0">
    <ng-container *ngIf="_dataService.notifications_data.length == 0">
      <div class="d-flex flex-column justify-content-center my-5 align-items-center">
        <div>
          <i class="fa fa-bell text-warning bell-icon" aria-hidden="true"></i>
        </div>
        <div class="text-center notification-content mt-4">
          <p class="content"><i>You've not received any notification yet</i></p>
          <p class="content"><i>You'll find all notifications from our store regarding</i></p>
          <p class="content"><i>latest offers and other updates in here.</i></p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngFor="let notification of _dataService.notifications_data">
      <div class="notification-wrapper p-3 border-bottom">
        <div class="d-flex">
          <div class="pe-2" *ngIf="notification.image">
            <img [src]="notification.image" width="100" height="100" [alt]="notification.title">
          </div>
          <div class="w-100">
            <h5 class="title">{{notification.title}}</h5>
            <div class="notification-discription" [innerHTML]="notification.description"></div>
            <p class="date mb-0 text-end">{{notification.createdDate | date}}</p>
          </div>
        </div>
      </div>
    </ng-container>
	</div>
</ng-template>

<!-- LOCATION -->
<ng-template #location let-modal>
  <div class="modal-body location-modal position-relative py-4 px-lg-4">
    <button type="button" class="btn-close modal-btn-close" (click)="modal.dismiss('Cross click');"></button>
    <div class="mt-md-4 mt-2">
      <div class="d-flex">
        <img src="assets/images/location.svg" alt="location-icon" class="location-icon-cls me-2">
        <h3 class="mb-0">Set your delivery location</h3>
      </div>
      <p class="sub-title">This helps us to serve you better.</p>
      <div class="mt-md-4 mt-2">
        <div class="col-12">
          <label for="search-location" class="form-label">Search Location</label>
          <input 
          type="text" 
          class="form-control search-place" 
          id="search-location" 
          placeholder="Type your city Society/Colony/Area">
        </div>
        <div class="my-2" *ngIf="locationError">
          <p class="location-error-message text-center">{{locationErrorText}}</p>
        </div>
        <div class="my-md-3 mt-1 mb-2">
          <p class="text-center divider">OR</p>
        </div>
        <div class="text-center d-flex flex-column flex-md-row justify-content-around">
          <button class="btn notification-btn" (click)="detectLocation()" type="button">
            <span *ngIf="!locationLoading">Use current location</span>
            <div *ngIf="locationLoading" class="spinner-border text-light spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
          <button *ngIf="locationErrorText" class="btn notification-btn outline mt-3 mt-md-0" (click)="explore()" type="button">
            Explore Your Notebook
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

