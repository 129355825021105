<div class="item">
    <div class="product-item">
        <div class="prod-img shadow position-relative">
            <div class="share-list-show" aria-label="share">
                <div class="fab btn-group show-on-hover dropup">
                    <div data-toggle="tooltip" data-placement="top" title="Share">
                        <button type="button" class="btn-io" aria-label="share-button">
                            <i class="fa fa-share-alt"></i>
                        </button>
                    </div>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu">
                        <li><a href="https://www.facebook.com" target="_blank" class="facebook"><i class="fa text-light fa-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com" target="_blank" class="instagram"><i class="fa text-light fa-instagram"></i></a></li>
                        <li><a href="https://twitter.com" target="_blank" class="twitter"><i class="fa text-light fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com" target="_blank" class="linkedin"><i class="fa text-light fa-linkedin"></i></a></li>
                        <li><a (click)="copyLink()" class="clipboard"><i class="fa text-light fa-clipboard"></i></a></li>
                    </ul>
                </div>
            </div>
            <a class="position-relative" [routerLink]="['/product/'+productDetail.id]" [queryParams]="{ category: getFormattedCategory.toLowerCase(), name: getFormattedName.toLowerCase()}">
                <div class="product-discount position-absolute start-0" *ngIf="productDetail.discount && productDetail.discount !== '0% off'">
                    <img src="assets/images/discount-b.png" style="height: 70px; width: 70px;" class="position-absolute top-0 start-0" alt="discount" role="presentation">
                    <p class="mb-0 position-absolute ">{{productDetail.discount | uppercase}}</p>
                </div>
                <img [src]="getPorudctImagePath+productDetail.displayImage" class="product-image img-fluid" [alt]="productDetail.name">
            </a>
        </div>
        <div class="prod-content">
            <div class="prod-name">
                <a [routerLink]="['/product/'+productDetail.id]" [queryParams]="{ category: getFormattedCategory.toLowerCase(), name: getFormattedName.toLowerCase()}"  class="text-decoration-none txt-black">
                    {{productDetail.name}}
                </a>
            </div>
            <div class="prod-rating-sec">
                <!-- <span class="font14 me-1" *ngIf="productDetail.avgRating">{{productDetail.avgRating}}</span> -->
                <app-rating [ngClass]="{'invisible': !productDetail.avgRating}" [rating]="productDetail.avgRating" [totalRating]="productDetail.totalRating"></app-rating>
                <!-- <span class="font14 fontw-400 ms-1" *ngIf="productDetail.totalRating">{{productDetail.totalRating}} ratings</span> -->
            </div>
            <div class="prod-price text-start">
                <span class="pro-rating-del me-1" *ngIf="productDetail.discount">Rs {{productDetail.unitPrice}}</span>
                <span class="current-prod-price">Rs {{productDetail.discountedPrice}}</span>
            </div>
            <div class="prod-add-btn" *ngIf="productDetail.inStock">
                <button class="slider-order-btn blob-btn add-to-cart-btn" *ngIf="!getProduct" [disabled]="isLoading" (click)="addToCart(productDetail.id)">
                    <span *ngIf="!isLoading">Add to cart</span>
                    <span class="blob-btn__inner">
                        <span class="blob-btn__blobs">
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                            <span class="blob-btn__blob"></span>
                        </span>
                    </span>
                    <div *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
    
                <div *ngIf="getProduct" class="btn-group cart-product-controls w-100" role="group">
                    <button type="button" [disabled]="isLoading" class="btn px-3" (click)="descreaseQuanity()">-</button>
                    <button role="button" [disabled]="isLoading" class="btn">{{getProduct.quantity}}</button>
                    <button type="button" [disabled]="isLoading || getProduct.quantity >= getProduct.maxLimit" class="btn px-3 border-0" (click)="increaseQuanity()">+</button>
                </div>
            </div>
            <div class="prod-add-btn" *ngIf="!productDetail.inStock">
                <button class="btn out-of-stock-btn btn-danger w-100" disabled>Out of stock</button>
            </div>
        </div>
    </div>
</div>