import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { generateUUID } from 'src/app/functions/uuid';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(private _router: Router, private _authService: AuthService, private _dataService: DataService) { }

  ngOnInit(): void {
  }

  logout(){
    this._authService._authenticated = false;
    this._authService.accessToken = null;
    this._authService._authStatus.next(null);
    localStorage.removeItem('yournotebook.token');
    localStorage.removeItem('yournotebook.cart');
    localStorage.setItem('x-device', generateUUID());
    this._router.navigate(['/home']);
    this._dataService.getNotification();
  }

}
