import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public url: string;

  constructor(private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.url = this._activatedRoute.snapshot.paramMap.get('url');
  }

}
