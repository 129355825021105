import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { ProductComponent } from './components/product/product.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductCateoryDialComponent } from './components/product-cateory-dial/product-cateory-dial.component';
import { ProductTabComponent } from './components/product-tab/product-tab.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ProductCarouselComponent } from './components/product-carousel/product-carousel.component';
import { RouterModule } from '@angular/router';
import { RatingComponent } from './components/rating/rating.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ProductReviewComponent } from './components/product-review/product-review.component';
import { LoginComponent } from './components/login/login.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { ToastrModule } from 'ngx-toastr';
/* GOOGLE MAPS */
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxMaskModule } from 'ngx-mask';

/** PIPE */
import { SafePipe } from '../pipes/safe.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ProductComponent,
    ProductCateoryDialComponent,
    ProductTabComponent,
    ProductCarouselComponent,
    RatingComponent,
    PageLoaderComponent,
    ProductReviewComponent,
    LoginComponent,
    ProfilePictureComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule,
    NgbModule,
    RouterModule,
    NgOtpInputModule,
    GoogleMapsModule,
    ToastrModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ProductComponent,
    ProductCateoryDialComponent,
    ProductTabComponent,
    ProductCarouselComponent,
    RatingComponent,
    PageLoaderComponent,
    ProductReviewComponent,
    LoginComponent,
    ProfilePictureComponent,
    CarouselModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    SafePipe
  ]
})
export class SharedModule { }
