import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from 'src/app/interfaces/home';
import { getImagePath, getLocationCode } from 'src/app/functions/imagePath';

@Component({
  selector: 'app-product-cateory-dial',
  templateUrl: './product-cateory-dial.component.html',
  styleUrls: ['./product-cateory-dial.component.scss']
})
export class ProductCateoryDialComponent implements OnInit {

  @Input('category') category: Category = null;

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  get getCategoryImagePath(): string {
    return `${getImagePath()}assets/category/wcdn/`;
  }

  public searchResult(){
    this._router.navigate(['search'], { queryParams: { category: this.category.name.replace(/\s+/g, '-').toString().replace(/---/g, '-').toLowerCase(), cid: this.category.id, sort: 'Discount', sort_order: 'ASC', page: 1 }, queryParamsHandling: 'merge' });
  }

}
