import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductDetail } from 'src/app/interfaces/home';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss']
})
export class ProductCarouselComponent implements OnInit, AfterViewInit {

  @Input('products') public products: Array<ProductDetail> = [];

  public customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    autoWidth: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    responsive: {
      0: {
        items: 2,
        margin: 20,
        autoWidth: true,
        loop: false
      },
      480: {
        items: 3,
        // margin: 20,
        autoWidth: true,
        loop: false
      },
      768: {
        items: 4,
        margin: 20,
        autoWidth: false,
        loop: false
      },
      1200: {
        items: 5,
        margin: 20,
        autoWidth: false,
        loop: false
      }
    },
    nav: true
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }


}
