<section class="order-summary my-3">
    <div class="container-xxl">

        <app-page-loader *ngIf="loading"></app-page-loader>

        <ng-container *ngIf="!loading">
            <div class="title-wrapper d-flex flex-wrap align-items-center">
                <h1 class="txt-black title">Order Summary</h1>
                <button (click)="getInvoice()" class="btn btn-link download-summary-link ms-md-3">Download Summary <i class="fa fa-download ms-1" aria-hidden="true"></i></button>
            </div>
    
            <div class="delivery-info">
                <div class="row justify-content-between">
                    <div class="col-lg-5 col-md-6 col-12">
                        <h2 class="my-4">Delivery details</h2>
                        <div class="delivery-info-card">
                            <div class="delivery-info-item">
                                {{orderSummary.order.orderDate | date}}
                            </div>
                            <!-- <div class="delivery-info-item">
                                <span class="txt-blue">Delivered in:</span> 5 minutes
                            </div> -->
                            <div class="delivery-info-item">
                                <span class="txt-blue">Order status:</span> <span class="delivery-status ms-1">{{orderSummary.order.orderStatus}}</span>
                            </div>
                            <!-- <div class="delivery-info-item">
                                <span class="txt-blue">Bag ID:</span> {{orderSummary.order.orderId}}
                            </div> -->
                        </div>
                    </div>
    
                    <div class="col-lg-5 col-md-6 col-12">
                        <h2 class="my-4">Delivery address</h2>
                        <div class="delivery-info-card">
                            <div class="delivery-info-item">
                                {{orderSummary.order.customerName}}
                            </div>
                            <div class="delivery-info-item">
                                {{orderSummary.order.address}}
                            </div>
                            <div class="delivery-info-item">
                                {{orderSummary.order.phoneNumber}}
                            </div>
                        </div>
                    </div>
    
                    <div class="col-12">
                        <h2 class="mt-5 mb-4">Payment details</h2>
                        <div class="delivery-info-card">
                            <div class="payment-info-item d-flex justify-content-between align-items-center">
                                <p>Order No:</p>
                                <p>{{orderSummary.order.orderId}}</p>
                            </div>
                            <div class="payment-info-item d-flex justify-content-between align-items-center">
                                <p>Payment method</p>
                                <p>{{orderSummary.order.paymentMode}}</p>
                            </div>
                            <!-- <div class="payment-info-item d-flex justify-content-between align-items-center">
                                <p>Order items</p>
                                <p>{{orderSummary.order.quantity}}</p>
                            </div> -->
                            <div class="payment-info-item d-flex justify-content-between align-items-center">
                                <p>Item total</p>
                                <p>{{orderSummary.items.length}}</p>
                            </div>
                            <div class="payment-info-item d-flex justify-content-between align-items-center">
                                <p>Delivery charges</p>
                                <p>{{orderSummary.order.deliveryCharges}}</p>
                            </div>
                            <div class="payment-info-item d-flex justify-content-between align-items-center">
                                <p>Discount</p>
                                <p>{{orderSummary.order.discount}}</p>
                            </div>
                            <div class="payment-info-item d-flex justify-content-between align-items-center">
                                <p class="total">Bill total</p>
                                <p class="total">{{orderSummary.order.totalAmount}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="my-5 delivery-items-wrapper">
                <h2 class="title mb-4">Delivery Items</h2>
                <div class="delivery-item-card w-100">
                    <ng-container *ngFor="let item of orderSummary.items">
                        <div class="delivery-item">
                            <div class="d-flex">
                                <a class="position-relative me-2 me-md-0" [routerLink]="['/product/'+item.productId]" [queryParams]="{ name: getFormatted(item.productName) }">
                                    <img [src]="getPorudctImagePath+item.displayUrl" class="delivery-item-image img-fluid" alt="name">
                                </a>
                                <div class="d-flex flex-column w-100">
                                    <div class="delivery-item-info d-flex flex-md-row flex-column justify-content-between align-items-start ms-md-4 me-md-5">
                                        <!-- <p class="delivery-item-name"> </p> -->
                                        <a class="delivery-item-name" [routerLink]="['/product/'+item.productId]" [queryParams]="{ name: getFormatted(item.productName) }">{{item.productName}}</a>
                                        <div class="d-flex align-items-center mb-1">
                                            <p class="delivery-item-discounted-price mb-0">Rs {{item.discountedPrice}}</p>
                                        </div>
                                    </div>
            
                                    <div class="delivery-item-info d-flex flex-md-row flex-column justify-content-between align-items-md-end ms-md-4 me-md-5">
                                        <div>
                                            <p class="delivery-item-quantity">Quantity: {{item.quantity}}</p>
                                            <div *ngIf="orderSummary.order.orderStatus === 'Delivered'" class="d-flex align-items-center mb-2" (click)="openReviewModal(write, item.productId)">
                                                <p class="delivery-item-rate">Rate item</p>
                                                <app-rating class="ms-3" [readOnly]="false" [control]="ratingControl" [rating]="item.rating ? item.rating : 0"></app-rating>
                                            </div>
                                        </div>
            
                                        <button *ngIf="!item.review && orderSummary.order.orderStatus === 'Delivered'" class="btn custom-primary-btn mb-3" (click)="openReviewModal(write, item.productId)">Write review</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
    
            <div class="my-5 d-flex flex-column flex-md-row">
                <button class="btn custom-primary-btn help-btn me-md-4 mb-4 rounded" [routerLink]="'/contact'">Help & Support</button>
                <button [routerLink]="'/return/'+orderId" *ngIf="orderSummary.order.orderStatus === 'Delivered'" class="btn custom-primary-btn help-btn mb-4 rounded">Return Order</button>
            </div>
        </ng-container>
    </div>
</section>


<!-- REVIEW MODAL -->
<ng-template #write let-modal>
	<div class="modal-body write-review-modal position-relative py-4 px-lg-4">
        <button type="button" class="btn-close modal-btn-close" (click)="modal.dismiss('Cross click')"></button>
        <app-product-review [ProductId]="productId" [OrderId]="orderId" [Rating]="ratingControl.value" (status)="onReviewSumit($event)"></app-product-review>
	</div>
</ng-template>