import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FAQ, FAQS } from 'src/app/interfaces/faqs';
import { DataService } from 'src/app/services/data.service';
import faqs from 'src/assets/json/faqs.json';

@Component({
  selector: 'app-faqs-type',
  templateUrl: './faqs-type.component.html',
  styleUrls: ['./faqs-type.component.scss']
})
export class FaqsTypeComponent implements OnInit {

  private category: string = '';
  public faqCategry: FAQS = null;
  public faqs: Array<FAQ> = [];
  public loading: boolean = true;
  constructor(private _activatedRoute: ActivatedRoute, private _dataService: DataService) { }

  ngOnInit(): void {
    this.loading = true;
    this.category = this._activatedRoute.snapshot.paramMap.get('type');
    this.faqCategry = faqs.find((f) => {return f.title == this.category});
    this._dataService.getFaqs(this.category).toPromise().then((res: any) => {
      this.faqs = res.result.faqs;
      this.loading = false;
    });
  }

}
