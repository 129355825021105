<section class="profile py-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-3 col-xl-2 offset-xl-1 profile--sidebar text-center ps-md-0 pe-md-4">
                <app-profile-picture></app-profile-picture>
            </div>
            <div class="col-12 col-md-9 border-start px-0">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <ngb-accordion #acc="ngbAccordion" activeIds="toggle-1, toggle-2, toggle-3" (panelChange)="beforeChange($event)">
                        <ngb-panel id="toggle-1">
                            <ng-template ngbPanelTitle>
                                <div class="accordion-header--icon">
                                    <img src="assets/images/user-icon.png" alt="user icon" />
                                </div>
                                <div class="accordion-header--heading">
                                    <h4>My Profile</h4>
                                    <p>It is a long established fact that a reader</p>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row g-3">
                                    <div class="col-md-4">
                                        <label for="first_name" class="form-label">First Name</label>
                                        <input maxlength="50" type="text" class="form-control" formControlName="firstName" id="first_name">
                                        <p *ngIf="form.get('firstName').touched && form.get('firstName').hasError('pattern')" class="text-danger"><small>Only Alphabates allowed</small></p>
                                        <p *ngIf="form.get('firstName').touched && form.get('firstName').hasError('maxlength')" class="text-danger"><small>Maximum 50 characters allowed</small></p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="middle_name" class="form-label">Middle Name</label>
                                        <input maxlength="50" type="text" class="form-control" formControlName="middleName" id="middle_name">
                                        <p *ngIf="form.get('middleName').touched && form.get('middleName').hasError('pattern')" class="text-danger"><small>Only Alphabates allowed</small></p>
                                        <p *ngIf="form.get('middleName').touched && form.get('middleName').hasError('maxlength')" class="text-danger"><small>Maximum 50 characters allowed</small></p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="last_name" class="form-label">Last Name</label>
                                        <input maxlength="50" type="text" class="form-control" formControlName="lastName" id="last_name">
                                        <p *ngIf="form.get('lastName').touched && form.get('lastName').hasError('pattern')" class="text-danger"><small>Only Alphabates allowed</small></p>
                                        <p *ngIf="form.get('lastName').touched && form.get('lastName').hasError('maxlength')" class="text-danger"><small>Maximum 50 characters allowed</small></p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="dob" class="form-label">DOB</label>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="input-group">
                                                    <input id="dob" class="form-control" [maxDate]="today" [minDate]="{day: 1, month: 1, year: 1800}"  placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
                                                        #d="ngbDatepicker" formControlName="dob" />
                                                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                                        <i class="fa fa-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="gender" class="form-label">Gender</label>
                                        <select id="gender" class="form-select" formControlName="gender">
                                            <option [value]="null">-- Select option --</option>
                                            <option [value]="'Male'">Male</option>
                                            <option [value]="'Female'">Female</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="email" class="form-label">Email</label>
                                        <input maxlength="50" type="email" class="form-control" formControlName="email" id="email" placeholder="yourmail@gmail.com">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="toggle-2">
                            <ng-template ngbPanelTitle>
                                <div class="accordion-header--icon">
                                    <img src="assets/images/education-icon.png" alt="education icon" />
                                </div>
                                <div class="accordion-header--heading">
                                    <h4>Education Details</h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row g-3 align-items-end">
                                    <div class="col-md-4">
                                        <label for="Who-are-you" class="form-label">Who are you?</label>
                                        <select id="Who-are-you" class="form-select" formControlName="userType">
                                            <option [value]="null">-- Select option --</option>
                                            <option value="School Student">School Student</option>
                                            <option value="College Student">College Student</option>
                                            <option value="Graduate">Graduate</option>
                                            <option value="Preparing for the exam">Preparing for the exam</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" class="form-control" formControlName="schoolName" id="school_name" [placeholder]="school_placeholder">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="toggle-3">
                            <ng-template ngbPanelTitle>
                                <div class="accordion-header--icon">
                                    <img src="assets/images/location-icon.png" alt="location icon" />
                                </div>
                                <div class="accordion-header--heading">
                                    <h4>Location Details</h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row g-3 align-items-end">
                                    <div class="col-md-4">
                                        <label for="country" class="form-label">Country</label>
                                        <select id="country" class="form-select" formControlName="countryId">
                                            <option [value]="null">-- Select option --</option>
                                            <ng-container *ngFor="let country of countires">
                                                <option [value]="country.id">{{country.name}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="state" class="form-label">State</label>                                        
                                        <select id="state" class="form-select" formControlName="stateId">
                                            <option [value]="null">-- Select option --</option>
                                            <ng-container *ngFor="let state of states">
                                                <option [value]="state.id">{{state.name}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="city" class="form-label">City / District</label>
                                        <select id="city" class="form-select" formControlName="cityId">
                                            <option [value]="null">-- Select option --</option>
                                            <ng-container *ngFor="let city of cities">
                                                <option [value]="city.id">{{city.name}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="zip" class="form-label">Zip Code</label>
                                        <input mask="000000" maxlength="6" type="number" class="form-control" formControlName="pin" id="zip">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <div class="text-center">
                        <button class="btn btn-lg custom-primary-btn" type="submit">
                            <span *ngIf="!form.disabled">Submit</span>
                            <div *ngIf="form.disabled" class="spinner-border spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>