<app-page-loader *ngIf="pageLoading"></app-page-loader>

<ng-container *ngIf="!pageLoading">
<!-- banner start -->
<div class="container-xxl">
    <div class="mt-2">
        <ngb-carousel *ngIf="pageData" [showNavigationArrows]="false" class="bannerslider" [showNavigationIndicators]="true">
            <ng-template ngbSlide *ngFor="let banner of pageData?.bannerAds">
                <img [src]="getBannerImagePath+banner" class="banner" loading="lazy" role="presentation" />
                <!-- <div class="carousel-caption">
                    <h3 class="slider-txt">Stationary Items Delivery</h3>
                    <button class="slider-order-btn blob-btn">Order Now <span class="blob-btn__inner">
                            <span class="blob-btn__blobs">
                                <span class="blob-btn__blob"></span>
                                <span class="blob-btn__blob"></span>
                                <span class="blob-btn__blob"></span>
                                <span class="blob-btn__blob"></span>
                            </span>
                        </span></button>
                </div> -->
            </ng-template>
        </ngb-carousel>
    </div>
</div>
<!-- Banner ends  -->

<!-- Shop By Categories start  -->
<section class="white-bg common-pd">
    <div class="container-xxl">
        <div class="main-title text-center mb-3">
            Shop by Category
        </div>
        <p class="font14 txt-black  text-center sub-title-width80">
            Shop by categories and explore our curated selection of stationery items. Whether you're a student or a professional, we've got you covered.
        </p>
        <!--  Demos -->
        <section class="categories-list">
            <div>
                <owl-carousel-o [options]="customOptions" *ngIf="pageData" class="owl-custom-nav">
                    <ng-template carouselSlide *ngFor="let category of pageData?.categories">
                        <app-product-cateory-dial [category]="category"></app-product-cateory-dial>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>
    </div>
</section>
<!-- Shop By Categories ends  -->

<!-- banner 2 start  -->
<section class="banner2">
    <div class="container-xxl">
        <img src="assets/images/homebanner2.png" alt="banner2" loading="lazy">
    </div>
</section>
<!-- banner 2 ends  -->

<!-- tabs start  -->
<section class="tabs-bg grey-bg common-pd mt-4">
    <div class="container-xxl">
        <div class="text-center position-relative">
            <ul ngbNav #nav1="ngbNav" (activeIdChange)="tabChange($event)" class="nav nav-tabs home-tabs-list">
                <li [ngbNavItem]="1">
                    <a ngbNavLink class="nav-link font28-bold d-flex flex-column align-items-center flex-md-row">
                        <img src="assets/images/on-sale.svg" class="tabs-icons" alt="On Sale"> <p class="mb-0">On Sale</p>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngIf="pageData">
                            <app-product-tab [categories]="pageData.categories" [product]="pageData.products[2]"></app-product-tab>
                        </ng-container>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink class="nav-link font28-bold d-flex flex-column align-items-center flex-md-row">
                        <img src="assets/images/top-rated.svg" class="tabs-icons" alt="Top Rated"> <p class="mb-0">Top Rated</p>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngIf="pageData">
                            <app-product-tab [categories]="pageData.categories" [product]="pageData.products[4]"></app-product-tab>
                        </ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav1" class="mt-4"></div>
        </div>
    </div>
</section>
 <!-- tabs ends  -->

<!-- why choose us start  -->
<div class="white-bg common-pd">
    <div class="container-xxl">
        <div class="main-title text-center mb-3">
              Why You Choose Us?
            </div>
            <div class="row mt-4">
                <div class="col-lg-3 col-md-6 col-6">
                    <div class="why-us-box shadow">
                        <div class="why-us-border">
                        <div class="why-icon">
                            <img src="assets/images/why-1.svg" alt="why-1">
                        </div>
                        <div class="why-title">EASY RETURNS & REFUND</div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6">
                    <div class="why-us-box shadow">
                        <div class="why-us-border">
                        <div class="why-icon">
                            <img src="assets/images/why-2.svg" alt="why-1">
                        </div>
                        <div class="why-title">SAFE DELIVERY</div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6">
                    <div class="why-us-box shadow">
                        <div class="why-us-border">
                        <div class="why-icon">
                            <img src="assets/images/why-3.svg" alt="why-1">
                        </div>
                        <div class="why-title">SUPER FAST DELIVERY</div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6">
                    <div class="why-us-box shadow">
                        <div class="why-us-border">
                        <div class="why-icon">
                            <img src="assets/images/why-4.svg" alt="why-1">
                        </div>
                        <div class="why-title">BEST PRICES & OFFERS</div>
                    </div>
                    </div>
                </div>
            </div>
    </div>
</div>
<!-- why choose us ends  -->

<!-- tabs start  -->
<section class="tabs-bg grey-bg common-pd mt-5">
    <div class="container-xxl">
        <div class="text-center position-relative">
            <ul ngbNav #nav2="ngbNav" (activeIdChange)="tabChange($event)" class="nav nav-tabs home-tabs-list">
                <li [ngbNavItem]="1">
                    <a ngbNavLink class="nav-link font28-bold d-flex flex-column align-items-center flex-md-row">
                        <img src="assets/images/featured-icon.svg" class="tabs-icons" alt="Featured Products"> <p class="mb-0">Featured Products</p>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngIf="pageData">
                            <app-product-tab [categories]="pageData.categories" [product]="pageData.products[3]"></app-product-tab>
                        </ng-container>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink class="nav-link font28-bold d-flex flex-column align-items-center flex-md-row">
                        <img src="assets/images/on-sale.svg" class="tabs-icons" alt="Trending Products"> <p class="mb-0">Trending Products</p>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngIf="pageData">
                            <app-product-tab [categories]="pageData.categories" [product]="pageData.products[1]"></app-product-tab>
                        </ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav2" class="mt-4"></div>
        </div>
    </div>
</section>
 <!-- tabs ends  -->

<!-- barcode start  -->
<section class="barcode-banner">
<div class="container">
    <img src="assets/images/home_bottom_banner.png" loading="lazy" class="img-fluid" alt="barcode">
    <!-- <div class="barcode-box">
    <img src="assets/images/barcode-banner.png" alt="barcode-banner">
    </div>
    <div class="banner2-content">
        <h1>Download Our App
        </h1>
        <p>Your First stationery choice
        </p>
    </div>
    <div class="row barcode-pos">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="row">
                <div class="col-md-4">
                    <div class="barcode-img">
                        <img src="assets/images/barcode-img.svg" alt="barcode-img">
                    </div>
                </div>
                <div class="col-md-8">
                    <ul class="barcode-list">
                        <li>
                            <img src="assets/images/tick-mark.svg" alt="tick-mark" width="20px">
                            <span class="font16">Easy return and refund</span>
                        </li>
                        <li>
                            <img src="assets/images/tick-mark.svg" alt="tick-mark" width="20px">
                            <span class="font16">100% secure payment</span>
                        </li>
                        <li>
                            <img src="assets/images/tick-mark.svg" alt="tick-mark" width="20px">
                            <span class="font16">Superfast delivery</span>
                        </li>
                    </ul>
                    <div class="barcode-btn">
                        <a href="https://www.apple.com/app-store/">
                            <img src="assets/images/appstore.svg" alt="app-store">
                        </a>
                        <a href="https://play.google.com/store/games?pli=1">
                            <img src="assets/images/playstore.svg" alt="google-store">
                        </a> 
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
</section>
<!-- barcode end  -->
</ng-container>