<section class="profile py-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-3 col-xl-2 offset-xl-1 profile--sidebar text-center ps-md-0 pe-md-4">
                <app-profile-picture></app-profile-picture>
            </div>
            <div class="col-12 col-md-9 account-cards--wrapper border-start px-0">
                <div class="row px-lg-5 px-md-4 m-0">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                        <div class="account-card d-flex pointer" [routerLink]="'/profile'">
                            <div class="me-2 mt-1">
                                <img src="assets/images/user-icon.svg" role="presentation" loading="lazy">
                            </div>
                            <div>
                                <h4 class="title">My Profile</h4>
                                <p class="sub-title">Manage your profile</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                        <div class="account-card d-flex pointer" [routerLink]="'/orders'">
                            <div class="me-2 mt-1">
                                <img src="assets/images/luggage-cart-line.svg" role="presentation" loading="lazy">
                            </div>
                            <div>
                                <h4 class="title">My Orders</h4>
                                <p class="sub-title">View your orders details</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                        <div class="account-card d-flex pointer" [routerLink]="'/delivery-address'">
                            <div class="me-2 mt-1">
                                <img src="assets/images/address-icon.svg" height="20" role="presentation" loading="lazy">
                            </div>
                            <div>
                                <h4 class="title">My Address</h4>
                                <p class="sub-title">Manage your addresses</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                        <div class="account-card d-flex pointer" [routerLink]="'/about-us'">
                            <div class="me-2 mt-1">
                                <img src="assets/images/mdi_about.svg" height="20" role="presentation" loading="lazy">
                            </div>
                            <div>
                                <h4 class="title">About</h4>
                                <p class="sub-title">Read more about us</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                        <div class="account-card d-flex pointer" [routerLink]="'/contact'">
                            <div class="me-2 mt-1">
                                <img src="assets/images/support-agent.svg" height="20" role="presentation" loading="lazy">
                            </div>
                            <div>
                                <h4 class="title">Help & Support</h4>
                                <p class="sub-title">Connect with us for any query</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                        <div class="account-card d-flex pointer" [routerLink]="'/faqs'">
                            <div class="me-2 mt-1">
                                <img src="assets/images/wpf_faq.svg" height="20" role="presentation" loading="lazy">
                            </div>
                            <div>
                                <h4 class="title">FAQs</h4>
                                <p class="sub-title">View FAQs</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 mb-4">
                        <div class="account-card d-flex pointer" (click)="logout()">
                            <div class="me-2 mt-1">
                                <img src="assets/images/logout.png" height="20" role="presentation" loading="lazy">
                            </div>
                            <div>
                                <h4 class="title">Logout</h4>
                                <p class="sub-title">User will logout from application</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>