import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-return-order',
  templateUrl: './return-order.component.html',
  styleUrls: ['./return-order.component.scss']
})
export class ReturnOrderComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    orderId: new FormControl(null, Validators.required),
    reason: new FormControl(null, Validators.required),
    comment: new FormControl(null, Validators.required)
  });

  @ViewChild('file') file: ElementRef;
  public files: Array<File> = [];
  public previewFiles: Array<string | ArrayBuffer> = [];
  public showFormError: boolean = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _dataService: DataService,
    private _toaster: ToastrService,
    private _router: Router) { }

  ngOnInit(): void {
    this.form.patchValue({
      orderId: this._activatedRoute.snapshot.paramMap.get('id')
    });
  }

  /* append file in to array */
  public appendFile(event) {
    this.files.push(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = () => {
      this.previewFiles.push(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  /* on submit form */
  onSubmit() {
    if (this.form.valid) {
      this.showFormError = false;
      this.form.disable();
      this._dataService.returnOrder(this.form.value, this.files).toPromise().then((res) => {
          this._router.navigate(['/orders']);
          this._toaster.success('Your request submitted.', 'Success');
          this._dataService.getNotification();
      }).catch((ex) => {
        this.form.enable();
        this._toaster.error(ex.error.Message, 'Error');
      });
    } else {
      this.showFormError = true;
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

}
