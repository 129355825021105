import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {

  @Input('ProductId') ProductId: string;
  @Input('OrderId') OrderId: string;
  @Input('Rating') Rating: number;
  @ViewChild('file') file: ElementRef;
  @Output('status') status: EventEmitter<boolean> = new EventEmitter();
  public files: Array<File> = [];
  public previewFiles: Array<string | ArrayBuffer> = [];

  public form: FormGroup = new FormGroup({
    Title: new FormControl(null, Validators.required),
    Rating: new FormControl(0, Validators.required),
    Message: new FormControl(null, Validators.required),
  });

  constructor(
    private _toaster: ToastrService,
    private _dataService: DataService,
    private _modalService: NgbModal) { }

  ngOnInit(): void {
    if(this.OrderId){
      this.form.addControl('OrderId', new FormControl(this.OrderId, Validators.required));
    }

    if(this.ProductId){
      this.form.addControl('ProductId', new FormControl(this.ProductId, Validators.required));
    }

    this.form.patchValue({
      Rating: this.Rating
    })
  }

  submit(){
    if(this.form.valid){
      this.form.disable();
      this._dataService.addReview(this.form.value, this.files).toPromise().then((res) => {
        this._toaster.success('Thank you for Your Review.', 'Success');
        this.status.emit(true);
        this._modalService.dismissAll();
      }).catch((ex) => {
        this.form.enable();
        this._toaster.error(ex.error.Message, 'Error');
      });
    }
  }

  public appendFile(event){
    this.files.push(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = () => {
      this.previewFiles.push(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

}
