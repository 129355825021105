import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { AuthUtils } from './auth/auth.utils';
import { generateUUID } from './functions/uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'YourNoetbook.WebApp';
  public loading: boolean = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  constructor(
    private router: Router,
    public _authService: AuthService){
    const x_device: string = localStorage.getItem('x-device');
    if(!x_device){
      localStorage.setItem('x-device', generateUUID());
    }
    this._authService.check().pipe(takeUntil(this._unsubscribeAll)).subscribe();
    if(AuthUtils.isTokenExpired(this._authService.accessToken)){
      this._authService.accessToken = '';
    }
    this.router.events.subscribe((events)=>{
      if(events instanceof NavigationEnd){
        /* scroll to top when routing change */
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    });
  }
 
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
