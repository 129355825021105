<button class="tabs-view-btn d-none d-md-block position-absolute end-0" (click)="gotoCategory()">View All</button>
<ul ngbNav #productNav="ngbNav" [(activeId)]="active" (activeIdChange)="tabChange($event)" class="nav nav-tabs home-tabs-list2">
    <li [ngbNavItem]="0">
        <a ngbNavLink>All Categories</a>
        <ng-template ngbNavContent>
            <ng-container *ngIf="!loading">
                <app-product-carousel [products]="product.productDetails"></app-product-carousel>
            </ng-container>
            <ng-container *ngIf="loading">
                <div class="text-center">
                    <div class="spinner-border text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </li>
    <ng-container *ngFor="let category of categories; index as i">
        <li [ngbNavItem]="i+1">
            <a ngbNavLink>{{category.name}}</a>
            <ng-template ngbNavContent>
                <ng-container *ngIf="!loading">
                    <app-product-carousel [products]="categoryProducts.productDetails"></app-product-carousel>
                </ng-container>
                <ng-container *ngIf="loading">
                    <div class="text-center">
                        <div class="spinner-border text-info" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </li>
    </ng-container>

</ul>
<div class="d-flex justify-content-end">
    <button class="d-block d-md-none view-all" (click)="gotoCategory()">View All</button>
</div>
<div [ngbNavOutlet]="productNav" class="mt-md-4 mt-0"></div>