import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
  });

  public form_submited: boolean = false;

  constructor(private _dataService: DataService, private _toaster: ToastrService) { }

  ngOnInit(): void {
  }

  public submit(){
    if(this.form.valid){
      this._dataService.subscribeNewsLetters(this.form.value).subscribe((res) => {
        this.form_submited = true;
      }, (ex) => {
        this._toaster.error(ex.error.Message, 'Error')
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

}
