<section class="return-order my-5">
    <div class="container-xxl">
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <h4>What is the issue with the item ?</h4>
            <div>
                <ul>
                    <li>
                        <div class="form-check">
                            <input class="form-check-input" value="Product damaged" type="radio" name="reason" formControlName="reason" id="flexRadioDefault1" [ngClass]="{'invalid': form.get('reason').invalid && showFormError }">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Product damaged
                            </label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check">
                            <input class="form-check-input" value="Wrong item was sent" type="radio" name="reason" formControlName="reason" id="flexRadioDefault2" [ngClass]="{'invalid': form.get('reason').invalid && showFormError }">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Wrong item was sent
                            </label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check">
                            <input class="form-check-input" value="Item defective spoilt, expired or doesnt work" type="radio" name="reason" formControlName="reason" id="flexRadioDefault3" [ngClass]="{'invalid': form.get('reason').invalid && showFormError }">
                            <label class="form-check-label" for="flexRadioDefault3">
                                Item defective spoilt, expired 
                                or doesnt work
                            </label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check">
                            <input class="form-check-input" value="Partial order delivered" type="radio" name="reason" formControlName="reason" id="flexRadioDefault4" [ngClass]="{'invalid': form.get('reason').invalid && showFormError }">
                            <label class="form-check-label" for="flexRadioDefault4">
                                Partial order delivered
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
            <h4>Add photos of the item ({{files.length}}/3)</h4>
            <p>Capture photos of each item seperately  with visible defects.</p>
            <div class="mb-3 form-field">
                <div class="d-flex">
                    <ng-container *ngFor="let file of previewFiles">
                        <img [src]="file" [alt]="file" class="img-preview me-2">
                    </ng-container>
                    <div class="img-box d-flex pointer justify-content-center align-items-center" (click)="file.click()" *ngIf="files.length !== 3">
                        <div class="text-center">
                            <p class="mb-0 plus">+</p>
                            <p class="mb-0 add-new position-relative">Add Now</p>
                        </div>
                    </div>
                </div>
    
                <input type="file" accept="image/*" (change)="appendFile($event)" #file hidden>
            </div>
            <h4>Comments</h4>
            <div class="mb-4">
                <textarea cols="30" rows="6" formControlName="comment" class="w-100 text-area" [ngClass]="{'invalid': form.get('comment').invalid && showFormError }"></textarea>
            </div>
            <div class="d-flex flex-md-row flex-column justify-content-evenly align-items-center">
                <button class="return-btn mb-4 mb-md-0" routerLink="/contact" type="button">Chat with Us</button>
                <button class="return-btn" type="submit">Submit Request</button>
            </div>
        </form>
    </div>
</section>