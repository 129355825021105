import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { DataService } from 'src/app/services/data.service';
import { getImagePath } from 'src/app/functions/imagePath';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

  public user: User = undefined;

  constructor(private _dataService: DataService, private _toaster: ToastrService) { }

  ngOnInit(): void {
    this._dataService.getUser().toPromise().then((user: User) => {
      this.user = user;
      this.user.profilePictureUrl = `${getImagePath()}assets/${this.user.profilePictureUrl}`;
    });
  }

  public getFile(event){

    let reader = new FileReader();
    reader.onload = () => {
      this.user.profilePictureUrl = reader.result;
    };
    reader.readAsDataURL(event.files[0]);

    const formdata = new FormData();
    formdata.append('formFile', event.files[0]);
    this._dataService.uploadProfilePicture(formdata).toPromise().then((res: any) => {
      this.user.profilePictureUrl = `${getImagePath()}assets/${res.result}`;
      this._toaster.success('Profile picture updated', 'Success');
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    })
  }

  get getImagePath(): string {
    return `${getImagePath()}assets/`;
  }

}
