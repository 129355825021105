<section class="about-us">
    <div class="container-xxl">
        <div class="content-wrapper">
            <div class="row py-12">
                <div class="col-lg-12 col-12 px-md-12 px-12 mt-lg-0 mt-12">
                    <h3 class="title">Account Deletion</h3>
                    <p class="content">We're sorry to see you go! If you wish to delete your account, please be aware of the following:  
                        <br/>
                        <b>1. Permanent Deletion:</b> Deleting your account is permanent. All your data, including your profile, settings, and associated information, will be permanently erased and cannot be recovered.
<br>

                        <b>2. How to Delete Your Account:</b><br/>
                        1. Go to the Profile section in the app.<br/>
                        2. Tap on the "Delete Account" option.<br/>
                        3. Follow the prompts to confirm your decision.<br/>
                        <b>3. Processing Time: </b>Account deletion requests are processed immediately, but it may take up to [24 Hours] for all data to be removed from our servers.
                        <br/>
                        <b>4. Reversing Deletion:</b> Once your account is deleted, it cannot be undone. If you wish to use our service again, you will need to create a new account.
                        <br>
                        <b>5. Contact Support:</b> If you encounter any issues during the deletion process or have any questions, please contact our support team at <a href="mailto:contactus@yournotebook.in">contactus@yournotebook.in</a>.
                    </p>
                </div>
            </div>            
        </div>
    </div>
</section>