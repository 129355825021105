<section class="faq-type">
    <div class="faq-type-bg d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex align-items-center flex-column flex-md-row faq">
            <h2 class="text-center text-light fa-4x">Return Policy</h2>
        </div>
    </div>

    <div class="container-xxl">
        <div class="my-5">
            <p class="text-end mb-5"><b>Last updated February 10, 2023</b></p>
            <p>
                Thank you for your purchase. We hope you are happy with your purchase. However, if you are not
                completely satisfied
                with your purchase for any reason, you may return it to us for a full refund only. Please see below for
                more
                information on our return policy.
            </p>

            <h2 class="mt-4">RETURNS</h2>
            <p>All returns must be postmarked within seven (7) days of the purchase date. All returned items must be in
                new and unused condition, with all original tags and labels attached.</p>

            <h2 class="mt-4">RETURN PROCESS</h2>

            <p>To return an item, please email customer service at contactus@yournotebook.in to obtain a Return Merchandise Authorisation (RMA) number. After receiving a RMA number, place the item securely in its original packaging and include your proof of purchase, then mail your return to the following address:
                <br>
                Return shipping charges will be paid or reimbursed by us.
            </p>

            <h2 class="mt-4">REFUNDS</h2>

            <p>After receiving your return and inspecting the condition of your item, we will process your return. Please allow at least seven (7) days from the receipt of your item to process your return. Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company.</p>

            <h2 class="mt-4">EXCEPTIONS</h2>
            <p>The following items cannot be returned:</p>
            <ul>
                <li>We do not accept return of used or damaged items</li>
            </ul>

            <p>For defective or damaged products, please contact us at the contact details below to arrange a refund or exchange.</p>
            <b>Please Note</b>

            <ul>
                <li>All returned items must be in new and unused condition. We cannot accept returns of used or damaged items. For defective or damaged products, please contact us immediately so we can arrange for a refund or exchange.</li>
                <li>We also offer fast delivery times, with items delivered in less than 59 minutes. Our speedy delivery is just one more way we strive to provide exceptional customer service.</li>
            </ul>

            <h2 class="mt-4">QUESTIONS</h2>
            <p class="mb-3">If you have any questions about our return policy or delivery times, please don't hesitate to contact us at:
               <br>
                <a href="tel:(+9172)49384189">(+9172)49384189</a>
                <br>
                <a href="mailto:contactus@yournotebook.in">contactus@yournotebook.in</a>
            </p>
        </div>
    </div>
</section>