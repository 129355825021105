import { Component, OnInit } from '@angular/core';
import { FAQS } from 'src/app/interfaces/faqs';
import faqs from 'src/assets/json/faqs.json';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  public faqs_categories: Array<FAQS> = faqs;

  constructor() { }

  ngOnInit(): void {
  }

}
