import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Product } from 'src/app/interfaces/product';
import { ShoppingCartResponse } from 'src/app/interfaces/shoppingCart';
import { DataService } from 'src/app/services/data.service';
import { getImagePath, getLocationCode } from 'src/app/functions/imagePath';
import { ToastrService } from 'ngx-toastr';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  public productId: number;
  public productDetail: Product = null;
  public loading: boolean = false;
  public primaryPicture: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public cartLoading: boolean = false;
  public buyLoading: boolean = false;
  constructor(
    private _dataService: DataService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _toaster: ToastrService,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    this.getProductDetails();
  }

  ngAfterViewInit(): void {
    this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((events)=>{
      if(events instanceof NavigationEnd){
        this.getProductDetails();
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  /* get product details */
  private getProductDetails(){
    this.loading = true;
    this.productId = parseInt(this._activatedRoute.snapshot.paramMap.get('id'));
    this._dataService.getProduct(this.productId).subscribe((res: Product) => {
      this.productDetail = res;
      this.primaryPicture = this.getPorudctImagePath+this.productDetail.displayImage;
      this.loading = false;
      this.logViewEvent('View Item');
    });
  }

  logViewEvent(eventName: any) {
      const gtmTag = {
        event: eventName,
        pageName: 'Product details',
        productId: this.productId
      };
      this.gtmService.pushTag(gtmTag);
  }

  public addToCart(){
    this.cartLoading = true;
    this._dataService.addToCart({productId: this.productId, quantity: 1}).toPromise().then((res: ShoppingCartResponse) => {
      this.storeCart(res)
      this.cartLoading = false;
      this.logViewEvent('Add to cart');
    });
  }

  public buyNow(){
    this.buyLoading = true;
    if(this.getProduct){
      this._router.navigate(['cart']);
    } else {
      this._dataService.addToCart({productId: this.productId, quantity: 1}).toPromise().then((res: ShoppingCartResponse) => {
        this.storeCart(res)
        this.buyLoading = false;
        this._router.navigate(['cart']);
        this.logViewEvent('Add to cart');
      });
    }
  }

  get getPorudctImagePath(): string {
    return `${getImagePath()}`;
  }

  public gotoProduct(id: number){
    if(this.productId !== id){
      this._router.navigate(
        [`/product/${id}`], 
        {
          relativeTo: this._activatedRoute,
          queryParams: { name: this.productDetail.name }, 
          queryParamsHandling: '', // remove to replace all query params by provided
        });
    }
  }

  private storeCart(cart: ShoppingCartResponse){
    localStorage.setItem('yournotebook.cart', JSON.stringify(cart));
  }

  get getProduct(){
    const cart: ShoppingCartResponse = JSON.parse(localStorage.getItem('yournotebook.cart'));
    if(cart && cart.cartItems){
      return cart.cartItems.find((item) => {
        return item.productId == this.productDetail.id;
      }); 
    } else {
      return null;
    }
  }

  public descreaseQuanity(){
    const cart: ShoppingCartResponse = JSON.parse(localStorage.getItem('yournotebook.cart'));
    cart.cartItems.forEach((item) => {
      if(item.productId == this.productDetail.id){
        item.quantity--;
      }
    });
    this.storeCart(cart);
    this._dataService.patchCart({productId: this.productDetail.id, quantity: this.getProduct.quantity}).toPromise().then((res: ShoppingCartResponse) => {
      this.storeCart(res);
    });
  }

  public increaseQuanity(){
    const cart: ShoppingCartResponse = JSON.parse(localStorage.getItem('yournotebook.cart'));
    cart.cartItems.forEach((item) => {
      if(item.productId == this.productDetail.id){
        item.quantity++;
      }
    });
    this.storeCart(cart);
    this._dataService.patchCart({productId: this.productDetail.id, quantity: this.getProduct.quantity}).toPromise().then((res: ShoppingCartResponse) => {
      this.storeCart(res);
    });
  }

  public copyLink(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${window.location.href}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toaster.info(`Product url is copied`, 'Copied');
  }

}
