import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AuthUtils } from '../auth/auth.utils';
import { environment } from 'src/environments/environment';
import { getLocationCode } from '../functions/imagePath';

@Injectable()
export class HandleRequestInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newReq = request.clone();
    if(!newReq.url.includes('maps.googleapis')){
      const x_device: string = localStorage.getItem('x-device');
      const x_location: string = localStorage.getItem('yournotebook.pcode');
      newReq = request.clone({
        headers: request.headers.set('x-location', x_location ? x_location : environment.x_location).set('x-device', x_device)
      });
      if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken))
      {
        newReq = request.clone({
          headers: request.headers.set('x-location', getLocationCode()).set('x-device', x_device).set('Authorization', `Bearer ${this._authService.accessToken}`)
        });
      } 
    }
    return next.handle(newReq)
  }
}
