<section class="about-us">
    <div class="container-xxl">
        <div class="content-wrapper">
            <div class="row py-4">
                <div class="col-lg-5 col-12">
                    <img src="assets/images/usstandout.png" loading="lazy" alt="What makes us stand out" class="img-fluid rounded-4">
                </div>
                <div class="col-lg-7 col-12 px-md-5 px-4 mt-lg-0 mt-4">
                    <h3 class="title">What makes us stand out</h3>
                    <p class="content">At Your Notebook, we are dedicated to delivering the best possible stationery shopping experience to our customers. Our focus on delivering high-quality products and a seamless shopping experience makes us stand out from the traditional and cluttered stationery market. With a focus on convenience, quality, and customer satisfaction, we aim to make stationery shopping quick, easy, and enjoyable. With our innovative app, you can order your favourite stationery items with just a few clicks, and have them delivered straight to your doorstep in minutes.</p>
                </div>
            </div>

            <div class="row py-4">
                <div class="col-lg-7 col-12 px-md-5 px-4 mt-lg-0 mt-4 order-lg-1 order-2">
                    <h3 class="title">Our Mission</h3>
                    <p class="content">Our mission is to make the process of stationery shopping as smooth and seamless as possible. We understand the frustration that can come with running out of necessary supplies or having to travel to multiple stores to find what you need. That's why we've created Your Notebook – a one-stop-shop for all your stationery needs. Whether you're a student, teacher, office worker, or simply someone who loves to be organised, we're here to help.</p>
                </div>
                <div class="col-lg-5 col-12 order-lg-2 order-1">
                    <img src="assets/images/our-mission.png" loading="lazy" alt="Our Mission" class="img-fluid rounded-4">
                </div>
            </div>

            <div class="row py-4">
                <div class="col-lg-5 col-12">
                    <img src="assets/images/what-we-envision.png" loading="lazy" alt="What makes us stand out" class="img-fluid rounded-4">
                </div>
                <div class="col-lg-7 col-12 px-md-5 px-4 mt-lg-0 mt-4">
                    <h3 class="title">What we envision</h3>
                    <p class="content">At Your Notebook, we envision a world where stationery shopping is stress-free, efficient, and enjoyable. We believe that with the right technology and approach, it's possible to make stationery shopping as convenient and accessible as ordering food or other household essentials. We are constantly working to improve our app and offerings to make your shopping experience even better, and we look forward to serving you for years to come.</p>
                </div>
            </div>
        </div>
    </div>
</section>