import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeliveryAddress } from 'src/app/interfaces/deliveryAddress';
import { getLocationCode } from 'src/app/functions/imagePath';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    id: new FormControl(0),
    fullName: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
    phoneNumber: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
    email: new FormControl(null, [Validators.email, Validators.maxLength(50)]),
    addressLine1: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
    addressLine2: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
    cityId: new FormControl(null, Validators.required),
    stateId: new FormControl(null, Validators.required),
    pin: new FormControl(getLocationCode(), [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
    landmark: new FormControl(null, Validators.maxLength(50)),
    addressType: new FormControl('Office', Validators.required),
    isDefault: new FormControl(true)
  });
  public redirect_path: string = null;

  public showFormError: boolean = false;
  private addressId;
  public toggleNickname: boolean = false;
  public states: Array<Common> = [];
  public cities: Array<Common> = [];

  constructor(
    private _dataService: DataService, 
    private _router: Router,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.addressId = this._activatedRoute.snapshot.paramMap.get('id');
    this.redirect_path = this._activatedRoute.snapshot.queryParamMap.get('redirect');
    if(this.addressId == 'new'){
      this.form.disable();
      this._dataService.lookup().toPromise().then((response: any) => {
        this.states = response.result[0].values;
        this.cities = response.result[1].values;
        this.form.enable();
      });
    } else {
      this.form.disable();
      this._dataService.lookup().toPromise().then((response: any) => {
        this.states = response.result[0].values;
        this.cities = response.result[1].values;
        let id = parseInt(this.addressId)
        this._dataService.getDeliveryAddressById(id).toPromise().then((address: DeliveryAddress) => {
          this.form.patchValue(address);
        });
        this.form.enable();
      });
    }    
  }

  public onSubmit(){
    if(this.form.valid){
      this.showFormError = false;
      this.form.disable();
      if(this.addressId == 'new'){
        this._dataService.addDeliveryAddress(this.form.value).subscribe((res) => {
          if(this.redirect_path){
            this._router.navigate([`/${this.redirect_path}`]);
          } else {
            this._router.navigate(['/delivery-address']);
          }
        });
      } else {
        this._dataService.updateDeliveryAddress(this.form.value, parseInt(this.addressId)).subscribe((res) => {
          if(this.redirect_path){
            this._router.navigate([`/${this.redirect_path}`]);
          } else {
            this._router.navigate(['/delivery-address']);
          }
        });
      }
    } else {
      this.showFormError = true;
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  public toggleNick(){
    this.form.get('addressType').setValue(null);
    this.toggleNickname = !this.toggleNickname;
  }

  public setAddressType(type: string){
    this.form.get('addressType').setValue(type);
  }

}


export interface Common {
  id: number;
  name: string;
}