import { environment } from 'src/environments/environment';
export function getImagePath(): string {
    let path = localStorage.getItem('yournotebook.blob');
    return path;
}

export function getLocationCode(): string {
    let code = localStorage.getItem('yournotebook.pcode'); 
    return code ? code : environment.x_location;
}

export function setLocationCode(postal_code: string): void {
    localStorage.setItem('yournotebook.pcode', postal_code);
}