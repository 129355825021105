<section class="faqs">
    <div class="container-xxl">
        <div class="text-center my-2">
            <img src="assets/images/faqs.svg" class="img-fluid" alt="FAQs">
        </div>

        <div class="faqs-categories mb-5">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-6 mt-4" *ngFor="let faq of faqs_categories" [routerLink]="'../faqs/'+faq.route">
                    <div class="faq">
                        <div class="text-center">
                            <img [src]="'assets/images/'+faq.icon" class="faq-icon" [alt]="faq.title">
                        </div>
                        <h2 class="text-center faq-title">{{faq.title}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>