import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderResponse, OrderSummary } from 'src/app/interfaces/order-summary';
import { DataService } from 'src/app/services/data.service';
import { getImagePath } from 'src/app/functions/imagePath';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  public orderId: string;
  public orderSummary: OrderSummary;
  public loading: boolean = false;
  public productId: string;
  public ratingControl: FormControl = new FormControl(0);
  constructor(
    private _modalService: NgbModal,
    private _activatedRoute: ActivatedRoute,
    private _dataService: DataService
  ) { }

  ngOnInit(): void {
    this.orderId = this._activatedRoute.snapshot.paramMap.get('id');
    this.getOrderSummary();
  }

  /* get Order Summary */
  private getOrderSummary(){
    this.loading = true;
    this._dataService.getOrderSummary(this.orderId).toPromise().then((summary: OrderResponse) => {
      this.orderSummary = summary.result;
      this.loading = false;
    });
  }

  public openReviewModal(modalRef, prodcutId){
    this.productId = prodcutId;
    setTimeout(() => {
      this._modalService.open(modalRef, { ariaLabelledBy: 'write-review-modal', centered: true });
    }, 500);
  }

  get getPorudctImagePath(): string {
    return `${getImagePath()}`;
  }

  getInvoice(){
    this._dataService.getInvoice(this.orderId).toPromise().then((res) => {
      this.downLoadFile(res, 'application/pdf')
    });
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  getFormatted(str: string): string{
    return str.replace(/\s+/g, '-').toString().replace(/---/g, '-').toLowerCase();
  }

  /* On Review Submit */
  public onReviewSumit(status){
    if(status){
      this.getOrderSummary();
    }
  }

}
