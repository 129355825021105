import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-payment-cancel',
  templateUrl: './payment-cancel.component.html',
  styleUrls: ['./payment-cancel.component.scss']
})
export class PaymentCancelComponent implements OnInit {

  constructor(
    private _dataService: DataService
  ) { }

  ngOnInit(): void {
    this._dataService.getNotification();
    const element: HTMLDivElement = document.querySelector('.razorpay-container');
    element.style.display = 'none';
  }

}
