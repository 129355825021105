import { Component, OnDestroy, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeApiResponse } from 'src/app/interfaces/home';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { getImagePath } from 'src/app/functions/imagePath';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public pageData: HomeApiResponse = null;
  public customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 5,
        margin: 20,
        autoWidth: true,
        loop: false
      }
    },
    nav: true
  }
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public loading: boolean = true;
  public pageLoading: boolean = true;
  public active: number = 11;

  constructor(
    private _homeService: HomeService,
    private _router: Router,
    private _searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.pageLoading = true;
    this._homeService.get().pipe(takeUntil(this._unsubscribeAll)).subscribe();
    this._homeService._homeData$.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: HomeApiResponse) => {
      if(response){
        this.pageData = response;
        this.pageLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  tabChange(event: any){
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  get getBannerImagePath(): string {
    return `${getImagePath()}assets/banner/wcdn/`;
  }

  /* goto search result page with select category */
  public gotoCategory(){
    this._router.navigate(['search'], { queryParams: { cid: this._searchService.cid }, queryParamsHandling: 'merge' });
  }

}
