import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SearchResult } from '../interfaces/searchresult';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public cid: number = 0;
  public rating: number = null;
  public price_start: FormControl = new FormControl(null);
  public price_end: FormControl = new FormControl(null);
  public product_id: number = null;
  public sort_order: string = 'ASC';
  public sort: string = 'Discount';
  public searchTerm: FormControl = new FormControl(null);
  public category: string;
  public page: number = 1;

  constructor(private _http: HttpClient) { }

  public searchAutocomplete(){
    return this._http.get(`${environment.baseApiUrl}search/autocomplete?search=${this.searchTerm.value}&cid=0`).pipe(map((response) => response));
  }

  public searchResult(){
    let query = 'search';
    if(this.searchTerm.value){
      query = `${query}${this.isFirst(query) ? '&q=' : '?q='}${this.searchTerm.value}`
    }
    if(this.cid){
      query = `${query}${this.isFirst(query) ? '&cid=' : '?cid='}${this.cid}`
    }
    if(this.rating){
      query = `${query}${this.isFirst(query) ? '&ratings=' : '?ratings='}${this.rating}`
    }
    if(this.price_start.value){
      query = `${query}${this.isFirst(query) ? '&price_start=' : '?price_start='}${this.price_start.value}`
    }
    if(this.price_end.value){
      query = `${query}${this.isFirst(query) ? '&price_end=' : '?price_end='}${this.price_end.value}`
    }
    if(this.product_id){
      query = `${query}${this.isFirst(query) ? '&product_id=' : '?product_id='}${this.product_id}`
    }
    if(this.sort){
      query = `${query}${this.isFirst(query) ? '&sort=' : '?sort='}${this.sort}`
    }
    if(this.sort_order){
      query = `${query}${this.isFirst(query) ? '&sort_order=' : '?sort_order='}${this.sort_order}`
    }
    if(this.page){
      query = `${query}${this.isFirst(query) ? '&page=' : '?page='}${this.page}`
    }
    return this._http.get<SearchResult>(`${environment.baseApiUrl}${query}`);
  }

  private isFirst(query: string){
    return query.includes('?');
  }
}
