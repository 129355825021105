import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subject, Subscription, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { AccessToken } from 'src/app/auth/auth.interface';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public isOTPSend: boolean = false;
  public countdown: number = 59;
  public resendOtp: boolean = false;
  public countdownMapping: any = {
    '=1'   : '# second',
    'other': '# seconds'
  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public timerSubscription: Subscription = null;
  @Output('onLogin') public onLogin: EventEmitter<boolean> = new EventEmitter();
  
  public otpForm: FormGroup = new FormGroup({
    phoneNumber: new FormControl(null, [Validators.required, Validators.pattern(/^\d{10}$/)]),
    acceptPolicy: new FormControl(false, Validators.requiredTrue)
  });

  public otpLogin: FormGroup = new FormGroup({
    username: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
    otp: new FormControl(null, [Validators.required, Validators.maxLength(4), Validators.minLength(4)])
  });

  constructor(
    private _dataService: DataService,
    public _authService: AuthService,
    private _modalService: NgbModal,
    private _toaster: ToastrService
  ) { }

  ngOnInit(): void {
  }

  /**
 * On destroy
 */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }

  /* get otp */
  public getOtp(){
    if(this.otpForm.invalid){
      return
    }
    this.otpForm.disable()
    this._dataService.getOtp(this.otpForm.value.phoneNumber.toString()).toPromise().then((res) => {
      this.otpLogin.get('username').setValue(this.otpForm.value.phoneNumber.toString());
      this.isOTPSend = true;
      this.otpForm.enable();
      this.resendOtp = false;

      // start timer
      this.timerSubscription = timer(1000, 1000)
      .pipe(
          finalize(() => {
            this.resendOtp = true;
            this.countdown = 59
          }),
          takeWhile(() => this.countdown >= 0),
          takeUntil(this._unsubscribeAll),
          tap(() => this.countdown--)
      )
      .subscribe();
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    });
  }

  /* send otp */
  public sendOtp(){
    if(this.otpLogin.valid){
      this.otpLogin.disable();
      this._dataService.otpLogin(this.otpLogin.value).toPromise().then((res: AccessToken) => {
        this._authService.accessToken = res.token;
        this._authService._authStatus.next(res.token);
        this._authService._authenticated = true;
        this.otpLogin.enable();
        this.onLogin.emit(true);
        this._modalService.dismissAll();
      }).catch((ex) => {
        this._toaster.error(ex.error.Message, 'Error');
        this.otpLogin.enable();
      });
    }
  }

  public onOtpChange(otp){
    this.otpLogin.get('otp').setValue(otp);
  }

  /* close modal */
  public close(){
    this._modalService.dismissAll();
  }

}
