<div class="d-flex flex-column my-4">
    <p class="title text-center">Write a Review</p>
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="mb-3 form-field">
        <label class="form-label">Review Title</label>
        <input type="text" class="form-control" formControlName="Title" placeholder="Give Your Review Title">
    </div>
    
    <div class="mb-3 form-field d-flex flex-column">
        <label class="form-label">Review Title</label>
        <app-rating [control]="this.form.get('Rating')" [rating]="Rating" [readOnly]="false"></app-rating>
    </div>
    
    <div class="mb-3 form-field">
        <label class="form-label">Body of Review <span>(600 Max)</span></label>
        <textarea cols="30" rows="5" class="form-control" formControlName="Message" placeholder="Wire Your Comments Here"></textarea>
    </div>
    
    <div class="mb-3 form-field">
        <label class="form-label">Add Photo ({{files.length}}/3)</label>
        <div class="d-flex">
            <ng-container *ngFor="let file of previewFiles">
                <img [src]="file" [alt]="file" class="img-preview me-2">
            </ng-container>
            <div class="img-box d-flex pointer justify-content-center align-items-center" (click)="file.click()" *ngIf="files.length !== 3">
                <div class="text-center">
                    <p class="mb-0 plus">+</p>
                    <p class="mb-0 add-new position-relative">Add Now</p>
                </div>
            </div>
        </div>

        <input type="file" accept="image/*" (change)="appendFile($event)" #file hidden>
    </div>
    
    <button class="btn mt-4 w-100 custom-primary-btn mb-3" type="submit" [disabled]="form.invalid">
        <span *ngIf="!form.disabled">Submit</span>
        <div *ngIf="form.disabled" class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </button>
</form>