<div class="item pointer">
    <div (click)="searchResult()" class="text-decoration-none">
        <div class="category-img">
            <img [src]="getCategoryImagePath+category.displayImage" [alt]="category.name">
            <div class="cat-offer-txt-bg" *ngIf="category.discount">
                <span class="cat-offer-txt">{{category.discount}}</span>
            </div>
        </div>
        <div class="font16-bold text-hover-blue">{{category.name}}</div>
    </div>
</div>