<section class="order-details py-3 py-md-5">
    <div class="container-xxl">
        <h1 class="title">Order Details</h1>
        <div class="mt-2">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="nav-tabs order-details-tab">
                <li [ngbNavItem]="'ngb-nav-0'">
                    <a ngbNavLink>All</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngTemplateOutlet]="table"></ng-container>
                    </ng-template>
                </li>
                <li [ngbNavItem]="0">
                    <a ngbNavLink>Pending</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngTemplateOutlet]="table"></ng-container>
                    </ng-template>
                </li>
                <li [ngbNavItem]="1">
                    <a ngbNavLink>In progress</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngTemplateOutlet]="table"></ng-container>
                    </ng-template>
                </li>
                <li [ngbNavItem]="7">
                    <a ngbNavLink>Delivered</a>
                    <ng-template ngbNavContent>
                        <ng-container [ngTemplateOutlet]="table"></ng-container>
                    </ng-template>
                </li>
            </ul>
            
            <div [ngbNavOutlet]="nav" class="mt-2 w-100"></div>
        </div>
    </div>
</section>


<!-- REVIEW MODAL -->
<ng-template #write let-modal>
	<div class="modal-body write-review-modal position-relative py-4 px-lg-4">
        <button type="button" class="btn-close modal-btn-close" (click)="modal.dismiss('Cross click')"></button>
        <app-product-review [OrderId]="orderId"></app-product-review>
	</div>
</ng-template>

<ng-template #table>
    <app-page-loader *ngIf="loading" [type]="'section'"></app-page-loader>
    <div *ngIf="!loading">

        <!-- SEARCH BAR -->
        <!-- <div class="d-flex align-items-center search-bar-wrapper">
            <div class="pe-3">
                <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <input type="text" class="search-field" placeholder="Search for order id and customers details">
        </div> -->

        <div class="table-responsive table-responsive-lg" *ngIf="orderDetails && orderDetails.length > 0">
            <table class="table w-100 text-nowrap">
                <thead>
                  <tr>
                    <th class="active" scope="col">Order ID</th>
                    <th scope="col">Delivery Date</th>
                    <th scope="col">Order Value</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Details</th>
                  </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let order of orderDetails">
                        <tr>
                            <td scope="row">
                                <div class="order-info d-flex flex-column">
                                    <p class="order-id">{{order.orderId}}</p>
                                    <!-- <p class="rating-label mb-0">Rate Order</p>
                                    <app-rating [rating]="5" class="mb-2"></app-rating> -->
                                </div>
                                <button class="btn table-btn" *ngIf="!order.review && order.orderStatus == 'Completed'" (click)="openReviewModal(write, order.orderId)">Write review</button>
                            </td>
                            <td>{{order.orderDate | date}}</td>
                            <td>Rs. {{order.totalAmount}}</td>
                            <td>
                                <div [ngSwitch]="order.orderStatus">
                                    <div *ngSwitchCase="'Pending'">
                                        <div class="delivery-status pending">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'In Progress'">
                                        <div class="delivery-status inprogress">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'InProgress'">
                                        <div class="delivery-status inprogress">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Delivered'">
                                        <div class="delivery-status delivered">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Return Requested'">
                                        <div class="delivery-status return-requested">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Return Accepted'">
                                        <div class="delivery-status return-accepted">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Return Rejected'">
                                        <div class="delivery-status return-rejected">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Return Completed'">
                                        <div class="delivery-status return-completed">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Refund Initiated'">
                                        <div class="delivery-status refund-initiated">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Refunded'">
                                        <div class="delivery-status refunded">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'AwaitingPayment'">
                                        <div class="delivery-status awaitingpayment">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Awaiting Payment'">
                                        <div class="delivery-status awaitingpayment">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'RefundRequested'">
                                        <div class="delivery-status refund-requested">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Refund Requested'">
                                        <div class="delivery-status refund-requested">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchDefault>
                                        <div class="delivery-status cancelled">
                                            <p class="status">{{order.orderStatus}}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="delivery-status delivered" [ngClass]="order.orderStatus | lowercase">
                                    <p class="status">{{order.orderStatus}}</p>
                                </div> -->
                            </td>
                            <td>{{order.paymentMode}}</td>
                            <td>
                                <button [routerLink]="'/order/'+order.orderId" class="btn table-btn">View Details</button>
                            </td>
                          </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <ng-container *ngIf="orderDetails && orderDetails.length == 0" [ngTemplateOutlet]="no_order"></ng-container>
    </div>
</ng-template>


<ng-template #no_order>
    <div class="container">
        <div class="row align-items-center justify-content-center my-5 my-lg-0">
            <div class="col-lg-6 col-12">
                <div class="text-center">
                    <h4>No Orders</h4>
                    <p><small>Go find to product you like</small></p>
                    <button class="custom-primary-btn px-5 py-2" (click)="gotoSearch()">Go Shopping</button>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <img src="assets/images/no-order.png" class="img-fluid" alt="no order">
            </div>
        </div>
    </div>
</ng-template>