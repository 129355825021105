<div class="d-flex flex-column my-4">
    <div>
        <img src="assets/images/login.svg" role="presentation">
    </div>
    <div>
        <p class="title">Sign In</p>
        <p class="sub-title">Get started with Your Notebook</p>
    </div>
    <!-- OTP PROCESS -->
    <ng-container *ngIf="!isOTPSend">
        <form (ngSubmit)="getOtp()" [formGroup]="otpForm">
            <div class="mb-3">
                <label class="form-label">* Enter your phone number</label>
                <div class="phone-field-wrapper d-flex align-items-center">
                    <div class="d-flex">
                        <img src="assets/images/india-flag.svg" loading="lazy" role="presentation">
                        <p class="country-code">+91</p>
                    </div>
                    <input mask="0000000000" formControlName="phoneNumber" class="number-field" placeholder="Mobile number">
                </div>
            </div>
            <div class="privacy-policy d-flex justify-content-between flex-wrap mb-3">
                <div class="form-check">
                    <input class="form-check-input" formControlName="acceptPolicy" id="accept-privacy" type="checkbox">
                    <label class="form-check-label" for="accept-privacy">
                        I accept the terms of Use & Privacy Policy
                    </label>
                </div>
                <a class="page-link mt-2 mt-md-0" routerLink="/terms" target="_blank" (click)="close()">| &nbsp; View T&C &nbsp; ></a>
            </div>
            <div class="text-center">
                <button class="btn rounded custom-primary-btn" [disabled]="otpForm.invalid" type="submit">
                    <span *ngIf="!otpForm.disabled">Send OTP</span>
                    <div *ngIf="otpForm.disabled" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </button>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="isOTPSend">
        <form (ngSubmit)="sendOtp()" [formGroup]="otpLogin">
            <div class="mb-3">
                <label class="form-label">* Enter your phone number</label>
                <div class="phone-field-wrapper d-flex align-items-center">
                    <div class="d-flex">
                        <img src="assets/images/india-flag.svg" loading="lazy" role="presentation">
                        <p class="country-code">+91</p>
                    </div>
                    <input type="text" formControlName="username" readonly class="number-field" placeholder="Mobile number">
                    <button class="btn edit-number" *ngIf="isOTPSend" (click)="isOTPSend = false; timerSubscription.unsubscribe();">
                        <img class="me-1" src="assets/images/blue-pen.svg" role="presentation" loading="lazy">
                        <span>Edit</span>
                    </button>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">* Enter the OTP</label>
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4, allowNumbersOnly: true}"></ng-otp-input>
            </div>
            <div class="my-3 text-center">
                <p *ngIf="!resendOtp" class="timer"><span class="timer-span">Resend</span> 
                    <ng-container *ngIf="countdown > 0">
                        {{countdown | i18nPlural: countdownMapping }}
                    </ng-container>
                </p>
                <div class="text-center">
                    <button *ngIf="resendOtp" class="btn rounded custom-primary-btn" (click)="getOtp()">Resend OTP</button>
                </div>
            </div>
            <div class="text-center">
                <button class="btn rounded custom-primary-btn" [disabled]="otpLogin.invalid" type="submit">
                    <span *ngIf="!otpLogin.disabled">Submit</span>
                    <div *ngIf="otpLogin.disabled" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </button>
            </div>
        </form>
    </ng-container>
</div>