<app-page-loader *ngIf="loading"></app-page-loader>
<ng-container *ngIf="!loading">
    <section class="success" *ngIf="status !== 'fail'; else fail_layout">
        <div class="container-xxl py-5">
            <div class="text-center">
                <h1>Your order has been received</h1>
                <p>Thank you for your purchase!</p>
                <div class=" mb-3">
                    <i class="fa fa-check-circle" style="font-size: 150px;
                    color: green;" aria-hidden="true"></i>
                </div>
                <p><small>Your order ID is : {{orderId}}</small></p>
                <p><small>Your will receive an order confirmation email with details of your order.</small></p>
        
                <button class="btn mt-5 custom-primary-btn" (click)="gotoHome()">
                    CONTINUE SHOPPING
                </button>
            </div>
        </div>
    </section>
    
    <ng-template #fail_layout>
        <section class="success my-3 payment-failed">
            <div class="container-xxl my-3">
                <div class="d-flex flex-column align-items-center">
                    <div class="d-flex align-items-center">
                        <i class="fa fa-exclamation-triangle" style="font-size: 35px;
                        color: #E93A14;" aria-hidden="true"></i>
                        <h1 class="mb-0 ms-3">Payment Failed</h1>
                    </div>
                    <p class="text-center text-danger">We're sorry, your payment was not successful.</p>
                    <img src="assets/images/payment-failed.png" alt="payment-failed" class="img-fluid">
                    <div class="detail">
                        <p>Please check your payment details and try again. If you continue to experience issues, please contact our customer support team for assistance.</p>
                    </div>
                    <div class="mt-4 d-flex flex-column flex-md-row justify-content-center">
                        <button class="btn custom-primary-btn help-btn me-md-4 px-5 mb-3 rounded" [routerLink]="'/cart'">Retry Payment</button>
                        <button class="btn custom-primary-btn outline help-btn me-md-4 px-5 mb-3 rounded" [routerLink]="'/contact'">Contact Support</button>
                    </div>
                </div>
                <div class="mt-4">
                    <div>
                        <h5 class="bold">Additional information</h5>
                        <ul>
                            <li>. Ensure that you have sufficient funds available in your account.</li>
                            <li>. Double-check that the billing information you entered is correct.</li>
                            <li>. Try using a different payment method.</li>
                            <li>. If you are still experiencing issues, please contact our customer support team for further assistance.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </ng-template>
</ng-container>