import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap, tap } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';
import { Autocomplete } from 'src/app/interfaces/autocomplete';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ShoppingCartResponse } from 'src/app/interfaces/shoppingCart';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { setLocationCode } from 'src/app/functions/imagePath';
import { HomeService } from 'src/app/services/home.service';
import { HomeApiResponse } from 'src/app/interfaces/home';
import { generateUUID } from 'src/app/functions/uuid';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbTypeaheadConfig]
})
export class HeaderComponent implements OnInit, AfterViewInit {

  public isCollapsed: boolean = true;
  public locationLoading: boolean = false;
  public locationSearch: FormControl = new FormControl(null);
  public locationError: boolean = false;
  public locationErrorText: string = '';
  @ViewChild('location') location_modal: ElementRef;

  constructor(
    config: NgbTypeaheadConfig,
    public _searchService: SearchService,
    public _dataService: DataService,
    private _router: Router,
    public _authService: AuthService,
    private _offcanvasService: NgbOffcanvas,
    private _homeService: HomeService,
    private _toaster: ToastrService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _modalService: NgbModal) { 
    config.placement = ['bottom-left'];
  }

  ngOnInit(): void {
    this._dataService.getNotification();
  }

  ngAfterViewInit(): void {
    let address  = localStorage.getItem('yournotebook.address');
    let pages = ['/home', '/product', '/search', '/cart'];
    if(!address){
      if(pages.includes(window.location.pathname)){
        this.openLocationModal(this.location_modal);
      }      
    }
  }

  /**
   * Format the result to show just the value
   * 
   * @param data the returned data from the api call
   * @returns value of object (ex: name)
   */
  formatter = (data: Autocomplete): string => {
    if (this._searchService.searchTerm.value !== '') {
      return data.name;
    } else {
      return data.name;
    }
  };

  inputFormatter = (data: string): string => {
    return this._searchService.searchTerm.value
  }

  /**
   * Recieves the search term and sends the api call if the number of characters the user entered into the typeahead is greater than or equal to 2
   * 
   * @param text$ the text entered by the user
   * @returns all matching results from the api call
   */
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 3
          ? of(this.getSearchHistory)
          : this._searchService.searchAutocomplete()
              .pipe(
                catchError(() => {
                  return of([]);
                })
              )
              .toPromise()
      )
    );
  };

  /**
   * Called when the user selects an item from the list of results
   * Used to emit the selected value
   * 
   * @param event the target of the click event
   */
  selectedItem(event) {
    event.preventDefault();
    this._searchService.product_id = event.item?.id;
    this._searchService.searchTerm.setValue(event.item?.name);
    this._router.navigate(['search'], { queryParams: { q: this._searchService.searchTerm.value , cid: this._searchService.cid, product_id: this._searchService.product_id, sort: 'Discount', sort_order: 'ASC', page: 1 }, queryParamsHandling: '' });
  }

  public searchResult() {
    const history: Array<any> = this.getSearchHistory;
    if(this._searchService.searchTerm.value){
      history.push({id: 0, name: this._searchService.searchTerm.value});
      if (history.length >= 5) {
        history.shift();
      }
      localStorage.setItem('yournotebook.history', JSON.stringify(history));
      this._router.navigate(['search'], { queryParams: { q: this._searchService.searchTerm.value , cid: this._searchService.cid, product_id: this._searchService.product_id, sort: 'Discount', sort_order: 'ASC', page: 1 }, queryParamsHandling: '' });
    }
  }

  /**
   * open login popup
   */
  public openLoginModal(modalRef){
    this._modalService.open(modalRef, { ariaLabelledBy: 'modal-login', centered: true });
  }

  /* get cart item count */
  get getCartItemCount(): number {
    const cart: ShoppingCartResponse = JSON.parse(localStorage.getItem('yournotebook.cart'));
    if(cart && cart.cartItems){
      return cart.totalItems;
    } else {
      return 0;
    }
  }

  /* get search history */
  get getSearchHistory(){
    const history = JSON.parse(localStorage.getItem('yournotebook.history'));
    return history ? history : [];
  }

  /* open notification drawer */
  openNotification(drawerRef){
    this._offcanvasService.open(drawerRef, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end' });
  }

  /* onUserLogin */
  onUserLogin(event){
    if(event){
      this._dataService.getNotification();
      this._dataService.getShoppingCart().toPromise().then((shoppingCart) => {
        localStorage.setItem('yournotebook.cart', JSON.stringify(shoppingCart));
      }).finally(() => {
        this._modalService.dismissAll();
      });
    }
  }

  /* open location modal */
  public openLocationModal(modalRef){
    this._modalService.open(modalRef, { ariaLabelledBy: 'modal-login', centered: true }).result.then(
			(result) => {
        this.locationError = false;
        this.locationErrorText = null;
			},
			(reason) => {
        this.locationError = false;
        this.locationErrorText = null;
			},
		);


    const options = {
      componentRestrictions: { country: "in" },
      strictBounds: false,
      types: ["establishment"],
    };

    let input = document.getElementById('search-location') as HTMLInputElement;
    input.value = this.userAddress;

    let places = new google.maps.places.Autocomplete(input, options);
    places.setComponentRestrictions({
        country: ["in"],
    });

    google.maps.event.addListener(places, 'place_changed', () => {
      this.locationError = false;
      this.locationLoading = true;
      let place = places.getPlace();
      
      let result = place.address_components.find((com) => {
          return com.types.includes("postal_code");
      });
      setLocationCode(result.long_name);
      this._dataService.getLookupLocation().toPromise().then((response) => {
        localStorage.setItem('yournotebook.blob', response.result['x-root']);
        if(response.result['x-iservicelocation']){
          setLocationCode(response.result['x-postalcode']);
          this.clearShoppingCart();
          localStorage.setItem('yournotebook.address', place.formatted_address);
          localStorage.setItem('x-device', generateUUID());
          if(window.location.pathname.includes('/home')){
            this._dataService.getHomeData().pipe(
              tap((apiData: HomeApiResponse) => {
                this._homeService._homeData.next(apiData);
                this._modalService.dismissAll();
              })
            ).subscribe();
            this.locationLoading = false;
            this._dataService.getNotification();
          } else {
            this._router.navigate(['/']);
            this._modalService.dismissAll();
            this.locationLoading = false;
            this._dataService.getNotification();
          }
        } else {
          this.locationErrorText = response.result['x-message'];
          this.locationLoading = false;
          this.locationError = true;
          window.dispatchEvent(new Event('resize'));
          localStorage.removeItem('yournotebook.address');
          this.clearShoppingCart();
          this._dataService.getNotification();
        }
      }).catch((ex) => {
        this._toaster.error(ex.error.message, 'Error');
        this._dataService.getLookupLocation().toPromise().then((response) => {
          setLocationCode(response.result['x-postalcode']);
        });
        this.locationLoading = false;
        this._dataService.getNotification();
      });

    });
  }

  /* detect location */
  public detectLocation(){
    this.locationError = false;
    if(navigator.geolocation){
      this.locationLoading = true;
      navigator.geolocation.getCurrentPosition((current) => {
        this._dataService.getCurrentLocation(current.coords).toPromise().then((res: any) => {
          setLocationCode(res.postal_Code);
          if(res.isServiceLocation){
            let input = document.getElementById('search-location') as HTMLInputElement;
            input.value = res.address;
            this.clearShoppingCart();
            localStorage.setItem('yournotebook.address', res.address);
            localStorage.setItem('x-device', generateUUID());
            if(window.location.pathname.includes('/home')){
              this._dataService.getHomeData().pipe(
                tap((apiData: HomeApiResponse) => {
                  this._homeService._homeData.next(apiData);
                  this._modalService.dismissAll();
                })
              ).subscribe();
            } else {
              this._router.navigate(['/']);
              this._modalService.dismissAll();
            }
            this._dataService.getNotification();
          } else {
            this._dataService.getHomeData().pipe(
              tap((apiData: HomeApiResponse) => {
                this._homeService._homeData.next(apiData);
              })
            ).subscribe();
            localStorage.removeItem('yournotebook.address');
            this.locationErrorText = res.message;
            this.locationError = true;
            this._dataService.getNotification();
          }
        }).catch((ex) => {
          this._toaster.error(ex.error.message, 'Error');
        }).finally(() => {
          this.locationLoading = false;
        })
      });
    } else {
      this._toaster.error('Geolocation is not supported by this browser', 'Error');
    }
  }

  /* clear cart */
  private clearShoppingCart(){
    localStorage.removeItem('yournotebook.cart');
    this._dataService.clearShoppingCart().subscribe();
  }

  /* get user address */
  get userAddress(): string {
    let address = localStorage.getItem('yournotebook.address');
    return address ? address : null;
  }

  /* explore yournote book */
  public explore() {
    this._dataService.getLookupLocation().toPromise().then((response) => {
      setLocationCode(response.result['x-postalcode']);
      if(window.location.pathname.includes('/home')){
        this._dataService.getHomeData().pipe(
          tap((apiData: HomeApiResponse) => {
            this._homeService._homeData.next(apiData);
            this._modalService.dismissAll();
          })
        ).subscribe();
        this.locationLoading = false;
      } else {
        this._router.navigate(['/']);
      }
      this.locationError = false;
      this.locationErrorText = null;
      this._dataService.getNotification();
      this._modalService.dismissAll();
    });
  }


}
