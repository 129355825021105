import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { OrderDetail, Order } from 'src/app/interfaces/order';
import { DataService } from 'src/app/services/data.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public orderDetails: Array<Order>;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public orderId: string;
  public activeTab = 'ngb-nav-0';
  constructor(
    private modalService: NgbModal,
    private _dataService: DataService,
    private _searchService: SearchService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.getOrders();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  public openReviewModal(modalRef, orderId){
    this.orderId = orderId;
    this.modalService.open(modalRef, { ariaLabelledBy: 'write-review-modal', centered: true });
  }

  public onNavChange(changeEvent: NgbNavChangeEvent) {
    this.activeTab = changeEvent.nextId;
    changeEvent.preventDefault();
    this.getOrders();
	}

  private getOrders(){
    this.loading = true;
    this._dataService.getOrder(this.activeTab).pipe(takeUntil(this._unsubscribeAll)).subscribe((result: OrderDetail) => {
      this.orderDetails = result.data;
      this.loading = false;
    }, (ex) => {
      this.loading = false;
      alert(ex.message);
    });
  }

  gotoSearch(){
    this._router.navigate(['search'], { queryParams: { q: this._searchService.searchTerm.value , cid: this._searchService.cid, product_id: this._searchService.product_id, sort: 'Discount', sort_order: 'ASC', page: 1 }, queryParamsHandling: '' });
  }

}
