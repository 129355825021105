import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { DeliveryAddress, DeliveryAddressResponse } from 'src/app/interfaces/deliveryAddress';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-delivery-addresses',
  templateUrl: './delivery-addresses.component.html',
  styleUrls: ['./delivery-addresses.component.scss']
})
export class DeliveryAddressesComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public deliveryAddresses: Array<DeliveryAddress> = [];
  public pageLoading: boolean = true;
  constructor(
    private _dataService: DataService,
    private _toaster: ToastrService,
    private _modalService: NgbModal) { }

  ngOnInit(): void {
    this.getAddress();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  /* GET ADDRESS */
  private getAddress(){
    this.pageLoading = true;
    this._dataService.getUserDeliveryAddress().pipe(takeUntil(this._unsubscribeAll)).subscribe((response: DeliveryAddressResponse) => {
      this.deliveryAddresses = response.data;
      this.pageLoading = false;
    });
  }

  public deleteAddress(modalRef, addressId){
    this._modalService.open(modalRef, { ariaLabelledBy: 'modal-login', centered: true }).result.then(
			(result) => {
				if(result){
          this.pageLoading = true;
          this._dataService.deleteDeliveryAddress(addressId).toPromise().then((response: DeliveryAddressResponse) => {
            this.getAddress();
          }).catch((ex) => {
            this._toaster.error(ex.error.Message, 'Error');
          })
        }
			},
			(reason) => {
        console.log(`Reason with: ${reason}`);
			},
		);
  }

}
