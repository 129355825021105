import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { FaqsTypeComponent } from './pages/faqs-type/faqs-type.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { DeliveryAddressesComponent } from './pages/delivery-addresses/delivery-addresses.component';
import { DeliveryAddressComponent } from './pages/delivery-address/delivery-address.component';
import { CartComponent } from './pages/cart/cart.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ProductDetailsComponent } from './pages/product/product.component';
import { PaymentCallbackComponent } from './pages/payment-callback/payment-callback.component';
import { PaymentCancelComponent } from './pages/payment-cancel/payment-cancel.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnComponent } from './pages/return/return.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ReturnOrderComponent } from './pages/return-order/return-order.component';
import { BaseComponent } from './shared/components/base/base.component';
import { AppResolver } from './app.resolver';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/'
  },
  {
    path: '',
    component: BaseComponent,
    resolve: {
      location: AppResolver
    },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'product/:id',
        component: ProductDetailsComponent
      },
      {
        path: 'faqs',
        component: FaqsComponent
      },
      {
        path: 'faqs/:type',
        component: FaqsTypeComponent
      },
      {
        path: 'search',
        component: SearchResultComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'delivery-address',
        component: DeliveryAddressesComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'delivery-address/:id',
        component: DeliveryAddressComponent
      },
      {
        path: 'cart',
        component: CartComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'return/:id',
        component: ReturnOrderComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'order/:id',
        component: OrderSummaryComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'orders',
        component: OrderDetailsComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'account',
        component: MyAccountComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        canActivate: [AuthGuard],
        path: 'payment-callback/:id',
        component: PaymentCallbackComponent
      },
      {
        path: 'payment-cancel',
        component: PaymentCancelComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'return',
        component: ReturnComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path: 'checkout/:url',
        component: CheckoutComponent
      },
      {
        path: 'account/delete',
        component: DeleteAccountComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
