import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, tap } from 'rxjs';
import { HomeApiResponse } from 'src/app/interfaces/home';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public _homeData: ReplaySubject<HomeApiResponse> = new ReplaySubject<HomeApiResponse>(null);
  public _homeData$: Observable<HomeApiResponse> = this._homeData.asObservable();

  constructor(private _dataService: DataService) { }

  /**
 * Get all navigation data
 */
  get(): Observable<HomeApiResponse>
  {
    return this._dataService.getHomeData().pipe(
      tap((apiData) => {
        this._homeData.next(apiData)
      })
    )
  }
}
