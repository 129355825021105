import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, Validators.required),
    message: new FormControl(null, [Validators.required, Validators.maxLength(300)])
  });

  constructor(
    private _dataService: DataService,
    private _toaster: ToastrService,
    private _modalService: NgbModal,
    private _router: Router) { }

  ngOnInit(): void {
  }

  public submit(modalRef){
    if(this.form.valid){
      this.form.disable();
      this._dataService.contact(this.form.value).toPromise().then((res) => {
        this.form.enable();
        this.form.reset();
        this._modalService.open(modalRef, {
          centered: true
        });
        this._router.navigate(['/'])
      });
    } else {
      this._toaster.error('All Fields must be fill');
    }
  }

}
