import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { setLocationCode } from '../functions/imagePath';
import { DeliveryAddressResponse } from '../interfaces/deliveryAddress';
import { HomeApiResponse, Product, Category } from '../interfaces/home';
import { Notifications } from '../interfaces/notification';
import { CreateOrder } from '../interfaces/order';
import { OrderResponse } from '../interfaces/order-summary';
import { ShoppingCartResponse } from '../interfaces/shoppingCart';
import { SimilarProduct } from '../interfaces/similarproducts';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public notifications_data: Notifications[] = [];

  constructor(private _http: HttpClient) { }

  public getLookupLocation(){
    return this._http.get(`${environment.baseApiUrl}lookUp/location`).pipe(
      tap((response: any) => {
        setLocationCode(response.result['x-postalcode']);
        localStorage.setItem('yournotebook.blob', response.result['x-root']);
        return response;
      })
    )
  }

  /* get Notification */
  public getNotification(){
    this.getNotifications().subscribe((notifications) => {
      this.notifications_data = notifications;
    });
  }

  public contact(data){
    return this._http.post(`${environment.baseApiUrl}contact`, data);
  }

  public getHomeData(){
    return this._http.get<HomeApiResponse>(`${environment.baseApiUrl}home`);
  }

  public getProducts(category: number, title: number){
    return this._http.get<Array<Product>>(`${environment.baseApiUrl}home/products?category=${category}&title=${title}`)
  }

  public getCategories(){
    return this._http.get<Array<Category>>(`${environment.baseApiUrl}category`);
  }

  public getFaqs(category: string){
    return this._http.get(`${environment.baseApiUrl}faqs/${category}`);
  }

  public lookup(){
    return this._http.get(`${environment.baseApiUrl}lookUp?fieldGroups=State&fieldGroups=city&fieldGroups=Country`)
  }

  public getSimilarProducts(prodcutId?: number){
    return this._http.get<SimilarProduct>(`${environment.baseApiUrl}product/similar?productid=${prodcutId}`)
  }

  public addDeliveryAddress(deliveryAddress){
    return this._http.post(`${environment.baseApiUrl}user/address`, deliveryAddress);
  }

  public updateDeliveryAddress(deliveryAddress, id){
    return this._http.put(`${environment.baseApiUrl}user/address/${id}`, deliveryAddress);
  }

  public getDeliveryAddressById(id: number){
    return this._http.get(`${environment.baseApiUrl}user/address/${id}`);
  }

  public deleteDeliveryAddress(id){
    return this._http.delete(`${environment.baseApiUrl}user/address/${id}`);
  }

  public getOtp(phoneNumber: string){
    return this._http.get(`${environment.baseApiUrl}authenticate/otp/${phoneNumber}`);
  }

  public getUserDeliveryAddress(){
    return this._http.get<DeliveryAddressResponse>(`${environment.baseApiUrl}user/address`);
  }

  public otpLogin(data){
    return this._http.post(`${environment.baseApiUrl}authenticate/otplogin`, data);
  }

  public getProduct(id){
    return this._http.get(`${environment.baseApiUrl}product/${id}`);
  }

  public getShoppingCart(){
    return this._http.get<ShoppingCartResponse>(`${environment.baseApiUrl}shoppingcart`);
  }

  public addToCart(data){
    return this._http.post(`${environment.baseApiUrl}shoppingcart`, data);
  }

  public patchCart(data){
    return this._http.patch(`${environment.baseApiUrl}shoppingcart`, data);
  }

  public shoppingCartGift(data){
    return this._http.patch(`${environment.baseApiUrl}shoppingcart/gift`, data);
  }

  public shoppingCartProduct(data){
    return this._http.patch(`${environment.baseApiUrl}shoppingcart/product`, data);
  }

  public clearShoppingCart(){
    return this._http.delete(`${environment.baseApiUrl}shoppingcart/clear`);
  }

  public applyCoupon(coupon:string){
    return this._http.patch(`${environment.baseApiUrl}shoppingcart/coupon/${coupon}`, null);
  }

  public removeCoupon(coupon:string){
    return this._http.delete(`${environment.baseApiUrl}shoppingcart/coupon/${coupon}`);
  }

  public removeProduct(id: number){
    return this._http.delete(`${environment.baseApiUrl}shoppingcart/product/${id}`);
  }

  // ORDER
  public getOrder(status?){
    if(status != 'ngb-nav-0'){
      return this._http.get(`${environment.baseApiUrl}order?orderStatus=${status}`);
    } else {
      return this._http.get(`${environment.baseApiUrl}order`);
    }
  }

  public getOrderSummary(orderId: string){
    return this._http.get<OrderResponse>(`${environment.baseApiUrl}order/${orderId}/summary`);
  }

  public createOrder(addressId: number, tip: number){
    return this._http.post<CreateOrder>(`${environment.baseApiUrl}order`, {addressId: addressId, tip: tip, paymentMode:"Online"});
  }

  public confirmOrder(data){
    return this._http.post(`${environment.baseApiUrl}Payment/confirm`, data);
  }

  public getInvoice(orderId){
    return this._http.get(`${environment.baseApiUrl}order/${orderId}/invoice`, {responseType: 'arraybuffer'});
  }

  // PROFILE
  public getUser(){
    return this._http.get<User>(`${environment.baseApiUrl}user`);
  }

  public updateUser(data: User){
    return this._http.put(`${environment.baseApiUrl}user`, data);
  }

  /* ADD Review */
  public addReview(data, files: Array<File>){
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    if(files){
      files.forEach((e) => {
        formData.append('form', e);
      })
    }
  
    return this._http.post(`${environment.baseApiUrl}ratereview`, formData);
  }

  /* get notifications */
  public getNotifications(){
    return this._http.get<Array<Notifications>>(`${environment.baseApiUrl}notification`);
  }

  /* get current location */
  public getCurrentLocation(coords: GeolocationCoordinates){
    coords
    return this._http.get(`${environment.baseApiUrl}lookUp/geolocation?latitude=${coords.latitude}&longitude=${coords.longitude}`);
  }

  /* get google places autocomplete */
  public googleAutocomplete(searchTerm: string){
    return this._http.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${searchTerm}&types=geocode&key=${environment.gApiKey}`)
  }

  /* upload profile picture */
  public uploadProfilePicture(data){
    return this._http.patch(`${environment.baseApiUrl}user`, data);
  }

  /* return order */
  public returnOrder(data, files: Array<File>){
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    if(files){
      files.forEach((e) => {
        formData.append('forms', e);
      })
    }
    return this._http.post(`${environment.baseApiUrl}Payment/refund`, formData);
  }

  public subscribeNewsLetters(formdata){
    return this._http.post(`${environment.baseApiUrl}notification/subscribe`, formdata);
  }

  public paymentStatus(paymentId){
    return this._http.get<{result: string, status: string}>(`${environment.baseApiUrl}Payment/status/${paymentId}`);

  }

  public updatecartAddress(addressId){
    return this._http.patch(`${environment.baseApiUrl}shoppingcart/address/${addressId}`, null);
  }
}
