<footer class="footer-cls">
  <div class="container-xxl">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="font28-bold">
          Subscribe to Our Newsletter
        </div>
        <div class="font16 pe-md-5">
          Stay in the loop with the latest deals and promotions by 
subscribing to our newsletter today. 
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="news-letter" *ngIf="!form_submited">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="news-letter-input-box">
              <input type="email" maxlength="50" formControlName="email" class="form-control" placeholder="Enter Your Email">
              <button type="submit" class="form-control subs-btn">Subscribe</button>
            </div>
            <p *ngIf="form.get('email').touched && form.get('email').hasError('maxlength')" class="text-light"><small>Maximum 50 characters allowed</small></p>
            <p *ngIf="form.get('email').touched && form.get('email').hasError('email')" class="text-light"><small>Enter valid email</small></p>
            <p *ngIf="form.get('email').touched && form.get('email').hasError('required')" class="text-light"><small>Enter insert your email.</small></p>
          </form>
        </div>
        <div class="news-letter" *ngIf="form_submited">
          <h4 class="text-light">Thankyou for subscribe our news letter.</h4>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12 pe-lg-5">
        <div class="ft-logo">
          <img src="assets/images/ft-logo.svg" alt="ft-logo">
        </div>
        <p class="font14">
          Your Notebook - your go-to destination 
for all your stationery needs.
        </p>
        <ul class="social-network social-circle">
          <li><a href="https://www.facebook.com/yournotebook.in" class="icoFacebook" title="Facebook"><i class="fa fa-facebook"></i></a></li>
          <li><a href="https://www.instagram.com/yournotebook.in/" class="icoinstagram" title="instagram"><i class="fa fa-instagram"></i></a></li>
          <li><a href="#" class="icoTwitter" title="Twitter"><i class="fa fa-twitter"></i></a></li>
          <li><a href="#" class="icoLinkedin" title="Linkedin"><i class="fa fa-linkedin"></i></a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="ft-links-title">Useful Links</div>
        <ul class="footer-links-list">
          <li>
            <a routerLink="/">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
              Home
            </a>
          </li>
          <li>
            <a routerLink="/terms">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
              Terms & Conditions
            </a>
          </li>
          <li>
            <a routerLink="/privacy">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
              Privacy Policy
            </a>
          </li>
          <li>
            <a routerLink="/return">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
              Return Policy
            </a>
          </li>
          <li>
            <a routerLink="/faqs">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
              FAQs
            </a>
          </li>
          <li>
            <a routerLink="/about-us">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
              About Us
            </a>
          </li>
          <li>
            <a routerLink="/contact">
              <i class="fa fa-caret-right" aria-hidden="true"></i>
              Contact
            </a>
          </li>
          <li>
            <a href="https://stationery.yournotebook.in">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
            Download App
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="ft-links-title">We accept payment methods</div>
        <div class="d-flex flex-wrap payment-cards mt-4">
          <div>
            <img src="assets/images/visacard.png" class="me-2 mt-2" alt="Visa">
          </div>
          <div>
            <img src="assets/images/master.png" class="me-2 mt-2" alt="Master Card">
          </div>
          <div>
            <img src="assets/images/googlepay.png" class="me-2 mt-2" alt="Google Pay">
          </div>
          <div>
            <img src="assets/images/paytm.png" class="me-2 mt-2" alt="Paytm">
          </div>
          <div>
            <img src="assets/images/phonepe.png" class="me-2 mt-2" alt="Phonepe">
          </div>
        </div>
        <!-- <ul class="footer-product-list">
          <li>
            <a href="#">
              <div class="ft-product-img">
                <img src="assets/images/ft-p1.svg" alt="product-1">
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="ft-product-img">
                <img src="assets/images/ft-p2.svg" alt="product-2">
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="ft-product-img">
                <img src="assets/images/ft-p3.svg" alt="product-3">
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="ft-product-img">
                <img src="assets/images/ft-p4.svg" alt="product-4">
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="ft-product-img">
                <img src="assets/images/ft-p5.svg" alt="product-5">
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div class="ft-product-img">
                <img src="assets/images/ft-p6.svg" alt="product-6">
              </div>
            </a>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</footer>
<div class="copy-right-footer">
  <p class="text-center">
    Copyright © 2023 Your Notebook. All Right reserved.
  </p>
</div>
