import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: ReplaySubject<string> = new ReplaySubject<string>(null);

    /**
     * Constructor
     */
    constructor()
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: string)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<string>
    {
        return this._user.asObservable();
    }
}
