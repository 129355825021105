<section class="faq-type">
    <div class="faq-type-bg d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex align-items-center flex-column flex-md-row faq">
            <div class="me-md-5">
                <img [src]="'assets/images/'+faqCategry.icon" class="faq-icon" [alt]="faqCategry.title">
            </div>
            <h2 class="text-center text-light fa-4x">{{faqCategry.title}}</h2>
        </div>
    </div>

    <div class="container-xxl">
        <div class="faqs-list my-5">
            <app-page-loader *ngIf="loading" type="section"></app-page-loader>
            <ng-container *ngIf="!loading">
                <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
                    <ng-container *ngFor="let faq of faqs; index as i">
                        <ngb-panel [title]="(i+1)+' '+faq.question">
                            <ng-template ngbPanelContent>
                                {{faq.answer}}
                            </ng-template>
                        </ngb-panel>
                    </ng-container>
                </ngb-accordion>
            </ng-container>
        </div>
    </div>
</section>