<app-page-loader *ngIf="page_loading"></app-page-loader>

<ng-container *ngIf="!page_loading">
    <section class="results-sec grey-bg py-3">
        <div class="container-xxl">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-12 d-none d-lg-block">
                    <div class="filter-wrapper-sec">
                        <div class="p-3 card">
                            <div class="filter-wrapper">
                                <div class="filter-title-sec">
                                    <span class="filter-title"> Filter</span>
                                    <span>
                                        <img src="assets/images/filter-icon.svg" alt="filter-icon">
                                    </span>
                                    <button class="apply-filter-btn" (click)="resetFilter()">Clear Filters</button>
                                </div>
                                <div class="row p-3">
                                    <div class="sort-by-blue-txt p-0 mb-2">Categories</div>
                                    <ul class="sort-by-list">
                                        <ng-container *ngFor="let category of categories">
                                            <li class="mb-2 pointer" [class.active]="category.id == _searchService.cid" (click)="setSearchCategory(category)"><span>{{category.name}}</span></li>
                                        </ng-container>
                                    </ul>
                                </div>
                                <div class="sort-by-blue-txt p-0 mb-2">Price Range</div>
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <div class="mb-3">
                                            <label for="min-price" class="form-label">Min</label>
                                            <input mask="00000" [patterns]="price_pattern" class="form-control" [formControl]="_searchService.price_start" id="min-price">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="mb-3">
                                            <label for="max-price" class="form-label">Max</label>
                                            <input mask="00000" [patterns]="price_pattern" class="form-control" [formControl]="_searchService.price_end" id="max-price">
                                        </div>
                                    </div>
                                    <div class="col-3 p-lg-0">
                                        <button class="apply-filter-btn w-100 mt-3" (click)="applyFilter()">
                                            GO
                                        </button>
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="sort-by-blue-txt p-0 mb-2">Customer Review </div>
                                    <div class="review-list-sec">
                                        <ul class="review-list p-0 mb-0">
                                            <ng-container *ngFor="let rating of [4,3,2,1]">
                                                <li class="mb-2 pointer" (click)="setSearchRating(rating)">
                                                    <app-rating [rating]="rating"></app-rating>
                                                    <span class="font14 ps-1" [class.bold]="rating == _searchService.rating">& UP</span>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 col-12">
                    <div *ngIf="_searchService.searchTerm.value || _searchService.category" class="products-results mb-3">Showing results for "<span class="blue-txt">{{_searchService.searchTerm.value ? _searchService.searchTerm.value : _searchService.category}}</span>"</div>
                    <div class="card p-3">
                        <div class="d-none d-lg-flex justify-content-end">
                            <div ngbDropdown class="d-inline-block sort-by-dropdown">
                                <button type="button" class="sort-dropdown-btn" id="sortby" ngbDropdownToggle>
                                    Sort by : <b>{{current_sortby_option.text}}</b>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="sortby">
                                    <ng-container *ngFor="let sort of sort_by">
                                        <button ngbDropdownItem [ngClass]="{'bold': sort.text == current_sortby_option.text}" (click)="setSearchSortby(sort)">{{sort.text}}</button>
                                    </ng-container>
                                </div>
                            </div>
                            <!-- <span class="sort-by-blue-txt">Sort By: </span>
                            <ul class="sort-by-list">

                            </ul> -->
                        </div>
                        <div class="d-block d-lg-none">
                            <button class="apply-filter-btn" (click)="openFilterLayout(filter_layout)">
                                Show Filters
                            </button>
                        </div>

                        <ng-container *ngIf="!product_loading && products.length > 0">
                            <div class="row">
                                <ng-container *ngFor="let product of products | paginate: {
                                    itemsPerPage: pagination?.pageSize,
                                    currentPage: current_page,
                                    totalItems: pagination?.totalRecordCount }">
                                        <div class="d-flex justify-content-center align-items-center col-xl-3 col-lg-4 col-md-4 col-6 mb-2">
                                            <app-product [productDetail]="product"></app-product>
                                        </div>
                                </ng-container>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <nav aria-label="Page navigation example">
                                        <div class="pagination justify-content-center custom-pagination">
                                            <pagination-controls 
                                            (pageChange)="changePage($event)"
                                            [maxSize]="10"
                                            [directionLinks]="true"
                                            [previousLabel]="'Previous'"
                                            [nextLabel]="'Next'"></pagination-controls>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container [ngTemplateOutlet]="no_result" *ngIf="!product_loading && products.length == 0"></ng-container>
                        <app-page-loader [type]="'section'" *ngIf="product_loading"></app-page-loader>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>

<!-- Filter MOBILE LAYOUT -->
<ng-template #filter_layout let-offcanvas>
    <div class="filter-wrapper-sec">
        <div class="text-end me-3 mt-3">
            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="px-3 filter-wrapper">
            <div class="">
                <div class="filter-title-sec">
                    <span class="filter-title"> Filter</span>
                    <span>
                        <a href=""><img src="assets/images/filter-icon.svg" alt="filter-icon"></a>
                    </span>
                    <button class="apply-filter-btn" (click)="resetFilter()">Clear Filters</button>
                </div>
                <div class="row p-3">
                    <div class="sort-by-blue-txt p-0 mb-2">Categories</div>
                    <ul class="sort-by-list">
                        <ng-container *ngFor="let category of categories">
                            <li class="mb-2 pointer" [class.active]="category.id == _searchService.cid" (click)="setSearchCategory(category)"><span>{{category.name}}</span></li>
                        </ng-container>
                    </ul>
                </div>

                <div class="row p-3">
                    <span class="sort-by-blue-txt p-0 mb-2">Sort By: </span>
                    <ul class="sort-by-list">
                        <ng-container *ngFor="let sort of sort_by">
                            <li class="mb-2 pointer" [class.active]="sort.text == current_sortby_option.text" (click)="setSearchSortby(sort)"><span>{{sort.text}}</span></li>
                        </ng-container>
                    </ul>
                </div>

                <div class="sort-by-blue-txt p-0 mb-2">Price Range</div>
                <div class="row align-items-center">
                    <div class="col-5">
                        <div class="mb-3">
                            <label for="min-price" class="form-label">Min</label>
                            <input mask="00000" [patterns]="price_pattern" class="form-control" [formControl]="_searchService.price_start" id="min-price">
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="mb-3">
                            <label for="max-price" class="form-label">Max</label>
                            <input mask="00000" [patterns]="price_pattern" class="form-control" [formControl]="_searchService.price_end" id="max-price">
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="apply-filter-btn mt-3" (click)="applyFilter()">
                            GO
                        </button>
                    </div>
                </div>

                <div class="row p-3">
                    <div class="sort-by-blue-txt p-0 mb-2">Customer Review </div>
                    <div class="review-list-sec">
                        <ul class="review-list p-0 mb-0">
                            <ng-container *ngFor="let rating of [4,3,2,1]">
                                <li class="mb-2 pointer" (click)="setSearchRating(rating)">
                                    <app-rating [rating]="rating"></app-rating>
                                    <span class="font14 ps-1" [class.bold]="rating == _searchService.rating">& UP</span>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- no result -->
<ng-template #no_result>
    <div class="d-flex flex-column justify-content-center align-items-center py-5 no-result">
        <img src="assets/images/no-result.png" class="img-fluid" style="max-width: 65%;" alt="no result">
        <p class="text">No Results Found</p>
        <p class="description">No content matched your criteria. Try searching for something else.</p>
        <button class="home-btn" routerLink="/">Go back to home page</button>
    </div>
</ng-template>