import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Category, Product } from 'src/app/interfaces/home';
import { DataService } from 'src/app/services/data.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-product-tab',
  templateUrl: './product-tab.component.html',
  styleUrls: ['./product-tab.component.scss']
})
export class ProductTabComponent implements OnInit, AfterViewInit, OnChanges {

  @Input('categories') public categories: Array<Category> = null;
  @Input('product') public product: Product = null;
  public categoryProducts: Product = null;
  public loading: boolean = true;
  public active: number = 0;

  constructor(
    private _dataService: DataService,
    private _searchService: SearchService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true;
  }

  ngAfterViewInit(): void {
    this.loading = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.loading = false;
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  tabChange(id: number){
    this.loading = true;
    if(id > 0){
      this._searchService.cid = this.categories[id - 1].id;
      this._searchService.category = this.categories[id - 1].name;
      this._dataService.getProducts(this._searchService.cid, this.product.titleId).toPromise().then((res) => {
        this.categoryProducts = res[0];
        setTimeout(() => {
          this.loading = false;
          window.dispatchEvent(new Event('resize'));
        }, 500);
      });
    } else {
      this._searchService.cid = 0;
      this._searchService.category = null;
      setTimeout(() => {
        this.loading = false;
        window.dispatchEvent(new Event('resize'));
      }, 500);
    }
  }

  /* goto search result page with select category */
  public gotoCategory(){
    // if (this._searchService.category) queryParams['category'] = this._searchService.category.replace(/\s+/g, '-').toString().replace(/---/g, '-').toLowerCase();
    if(this.active > 0){
      let category = this.categories[this.active - 1].name.replace(/\s+/g, '-').toString().replace(/---/g, '-').toLowerCase();
      this._router.navigate(['search'], { queryParams: {category: category, cid: this._searchService.cid, sort: 'Discount', sort_order: 'ASC', page: 1}, queryParamsHandling: 'merge' });
    } else {
      this._router.navigate(['search'], { queryParams: {cid: this._searchService.cid, sort: 'Discount', sort_order: 'ASC', page: 1}, queryParamsHandling: 'merge' });
    }
  }

}
